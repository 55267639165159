import {combineReducers} from "redux"
import {GET_API_STATUS, GET_INFO_REDUCER, GET_SCORECARD_QUESTION_DETAIL, GET_TIME_LEFT, timerReducer} from "./reducer"
export default combineReducers(
    {
        GET_INFO_REDUCER:GET_INFO_REDUCER,
        GET_SCORECARD_QUESTION_DETAIL:GET_SCORECARD_QUESTION_DETAIL,
        GET_TIME_LEFT:GET_TIME_LEFT,
        GET_API_STATUS:GET_API_STATUS,
        // timer: timerReducer,
    }
)