import React, { useEffect, useState } from 'react'
import AuthLayout from '../layout/AuthLayout';
import Nmat from '../players/Nmat';
import { useSelector } from 'react-redux';
import Atma from '../players/Atma';
import Nta from '../players/Nta';
import style from "../pages/style.module.css"

// ________ This is a Component where we can check the player and render the component according to player _______
export const PlayerSelection = ({ infoData }) => {

    const [PlayerStatus, setPlayerStatus] = useState({})

    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    useEffect(() => {
        if (Object.keys(selector).length > 0) {
            setPlayerStatus(selector)
        }
    }, [selector])



    const info = {
        "id": "6886",
        "name": "SimCAT 15 - 2018",
        "player": 0,
        "instructions": [
            {
                "id": 1,
                "status": 2,
            }
        ],
        "marks_obtained": null,
        "show_feedback": false,
        "status": 0,
        "submit_group": 1,
        "group_details": [
            {
                "name": "Language Skills",
                "id": 218,
                "status": 0,
                "view_pdf": "https://ct-question-jsons.s3.ap-southeast-1.amazonaws.com/SSC_Mock_test_instructions_English.pdf#toolbar=0&navpanes=0&scrollbar=0",
                "question_pdf": "https://ct-question-jsons.s3.ap-southeast-1.amazonaws.com/SSC_Mock_test_instructions_English.pdf#toolbar=0&navpanes=0&scrollbar=0",
                "section_detail": []
            }
        ]
    }

    return (
        <>
            {PlayerStatus?.player == 4 ? <Nmat /> : ""}
            {PlayerStatus?.player == 3 ? <Atma /> : ""}
            {PlayerStatus?.player == 6 ? <Nta /> : ""}

        </>
    )
}

const Home = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // function handleOnline() {
        //     setIsOnline(true);
        // }

        function handleOffline() {
            setIsOnline(false);
        }

        // window.addEventListener('online', checkOnlineStatus);
        window.addEventListener('offline', handleOffline);

        return () => {
            // window.removeEventListener('online', checkOnlineStatus);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const checkOnlineStatus = () => {
        setIsOnline(navigator.onLine);
    }


    // api status 
    const selector = useSelector((state) => state.GET_API_STATUS)

    const [non200api, setNon200Api] = useState(false)
    useEffect(() => {
        if (selector.status) {
            setNon200Api(true)
        }
    }, [selector])
    return (
        <div>
            {/* ______________ covered with auth_layout so that only authenticated user enters_______________ */}
            <AuthLayout>
                {
                    isOnline ?
                        <>
                            {non200api ? <div className={style["technicalIssue"]}>
                                <div className={style["technicalIssue-inner"]}>
                                    <h2>Oops !</h2>
                                    <div className={style['discription']}>We've encountered a technical issue.</div>
                                    <div className={style['discription']}>No worries, your progress has been securely saved.</div>
                                    <div className={style['discription']}>Just <b>close the window </b> and re-launch the test to pick up right where you left off!</div>
                                </div>
                            </div> : <PlayerSelection />}

                        </>
                        :
                        <div className={style["no-internet-modal"]}>
                            <div className={style["no-internet"]}>
                                <h2>Internet Disconnected !</h2>
                                <div className={style['discription']}>“Uh-oh! Looks like you've lost your internet connection. Please ensure a stable connection before proceeding and try again.”</div>
                                <button className={style['button-reconnect']} onClick={() => checkOnlineStatus()}>Reconnect</button>
                            </div>
                        </div>
                }


            </AuthLayout>
        </div>
    )
}

export default Home