// import { BASIC_INFORMATION, CLEAR_ALL_DATA, GET_INFO, QUESTION_CREATION_FINAL_CALL, QUESTION_CREATION_SCREEN_BACK, QUESTION_CREATION_SCREEN_CANCEL, QUESTION_CREATION_SCREEN_CHANGE, QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK, SCREEN_TO_ZERO, SELECT_QUESTION_BANK } from "./constant"

import { GET_API_STATUS_NON_TWO_HUNDRES, GET_GROUP_SUCCESSED, GET_INFO, GET_SELECTED_QUESTION_DETAILS_REQUESTED, GET_TIME_LEFT, GET_TIME_LEFT_INSTRUCTION_SUCCESSED, GET_TIME_LEFT_OTHER, GET_TIME_LEFT_SUCCESSED, PING } from "./constant"

export const GetInfo=(testId)=>{
    return{
        type:GET_INFO,
        testId:testId
    }
}


export const selectedQuestionDetails = (qid) => {
    return {
      type: GET_SELECTED_QUESTION_DETAILS_REQUESTED,
      qid,
    };
  };


  export const GetTimeleft=(testId, groupID)=>{
    return{
        type:GET_TIME_LEFT,
        testId:testId,
        groupID:groupID
    }
}

export const GetTimeleftOthers=(testId, groupID)=>{
  return{
      type:GET_TIME_LEFT_OTHER,
      testId:testId,
      groupID:groupID
  }
}

export const PutTime=(data)=>{
  // console.log(data)
  return{
      type:GET_TIME_LEFT_INSTRUCTION_SUCCESSED,
      data:{data:data}
  }
}


export const Ping = testId => {
  return {
    type: PING,
    testId
  }
}

export const GroupSuccessed=(data)=>{
  return{
      type:GET_GROUP_SUCCESSED,
      data:data
  }
}

export const ApiStatusforNon200=(data)=>{
  // console.log("ApiStatusforNon200",data)
  return{
    type:GET_API_STATUS_NON_TWO_HUNDRES,
    data:data
  }
}