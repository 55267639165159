


// ACTIONS 
export const GET_INFO = 'GET_INFO';
export const GET_SELECTED_QUESTION_DETAILS_REQUESTED = "GET_SELECTED_QUESTION_DETAILS_REQUESTED";
export const GET_TIME_LEFT = 'GET_TIME_LEFT';
export const GET_TIME_LEFT_SUCCESSED="GET_TIME_LEFT_SUCCESSED";

export const GET_TIME_LEFT_OTHER = 'GET_TIME_LEFT_OTHER';
export const GET_TIME_LEFT_OTHER_SUCCESSED="GET_TIME_LEFT_OTHER_SUCCESSED";
export const GET_TIME_LEFT_INSTRUCTION_SUCCESSED="GET_TIME_LEFT_INSTRUCTION_SUCCESSED"

// REDUCERS 
export const GET_INFO_DATA= "GET_INFO_DATA";
export const GET_SELECTED_QUESTION_DETAILS_SUCCEEDED = "GET_SELECTED_QUESTION_DETAILS_SUCCEEDED";
export const GET_GROUP_SUCCESSED="GET_GROUP_SUCCESSED"


export const PING = "PING"


export const GET_API_STATUS_NON_TWO_HUNDRES="GET_API_STATUS_NON_TWO_HUNDRES"