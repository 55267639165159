import React, { useEffect, useState } from 'react'
import { CallForAuth, CallWithAuth } from '../action/apiActions'
import { GET_AUTHENTICATION, POST_REFRESH_AUTH } from '../action/apiPath'
import Cookies from 'js-cookie'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetInfo } from '../redux/action'

const AuthLayout = ({ children, pageName }) => {

  // ________ initialization :start ____________
  const [queryParameters] = useSearchParams()
  // ________ initialization :end ____________


  const [Authenticated, setAuthenticated] = useState(false)


  // Start _______ getting the authtoken from the url and storing it in Cookies 
  const login = async (params) => {
    const token = params
    const checkAuth = await CallForAuth(`jwt ${token}`, "GET", GET_AUTHENTICATION)
    // console.log(checkAuth)
    if (checkAuth.status && checkAuth.res.data.jwt != null) {
      Cookies.set('accessToken', checkAuth.res.data.jwt)
      Cookies.set('refreshToken', checkAuth.res.data.jwt_refresh)
      Cookies.set('firstName', checkAuth.res.data.first_name)
      Cookies.set('lastName', checkAuth.res.data.last_name)
      setAuthenticated(true)
    } else {
      Cookies.set('accessToken', null)
      setAuthenticated(false)
    }

  }

  useEffect(() => {
    login(queryParameters.get("auth_tok"))
  }, [queryParameters])
  //_______ getting the authtoken from the url and storing it in Cookies_______ END:


  // ______________ fetch API to get the data from INFO api and store it ______________


  const dispatch= useDispatch()
  useEffect(() => {
    if (Authenticated) {
      dispatch(GetInfo(queryParameters.get("test_id")))
    }
  }, [Authenticated])




  return (
    <>
      {Authenticated ?
        <section id="dashboard">
         {children}
        </section>
        : ""}

    </>
  )
}

export default AuthLayout