import "./style.css"

import style from '../Modals/modalStyle.module.css';

export const Loader = ({ modal, player }) => {
    return (
        <>

            {modal ?
                <>
                    {player == 0 &&
                        <div className={style.backGround}>
                            <div>
                                <img src={'https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif'} width={80} height={80} />
                            </div>
                        </div>
                    }

                    {player == 1 &&
                        <div className={style.backGroundWhite}>
                            <div className={style.loader_div}>
                                <h1>Processing</h1>
                                <img src={'https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif'} width={40} height={40} />
                                <p>Please wait</p>
                            </div>
                        </div>
                    }
                </>
                : ""}

        </>
    )
}