import React, { useEffect, useRef, useState } from 'react'
import "./style.css"
import { NmatModalwithoutBackground } from '../../Components/Modals/NmatModalwithoutBackground'
import { DecodedHTMLComponent } from '../../Components/Utils/DecodeHTMLComponent'
import { CountdownTimer } from '../../Components/Utils/NmatTimerCoundown'
import { useDispatch, useSelector } from 'react-redux'
import { CallWithAuth } from '../../action/apiActions'
import { GetNmatInstructionUrl, NmatQuestionStatusViewUrl, NmatQuestionSubmitUrl, NmatQuestionViewUrl, NmatSectionSubmitUrl, NmatTimeLeft, SubmitNmatInstructionUrl } from '../../action/apiPath'
import { ApiStatusforNon200, GetInfo, GetTimeleft, GroupSuccessed, Ping, PutTime } from '../../redux/action'
import { useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { io } from 'socket.io-client'
import { Loader } from '../../Components/Modals/Loader'

import style from '../Nmat/nmatStyle.module.css';
import useMobileCheck from './MobileCheck'
import { NotCompatible } from '../../Components/NotCompatible'

export const ExamSectionOrder = ({ data, setSelectedIndex }) => {



    useEffect(() => {
        const handleRadioClick = (event) => {
            const selectedValue = event.target.value;
            setSelectedIndex(selectedValue)
            // console.log('Selected Value:', selectedValue);
            // Add your additional logic here...
        };

        // Attach click event listeners using getElementById
        const radio0 = document.getElementById('section0');
        const radio1 = document.getElementById('section1');
        const radio2 = document.getElementById('section2');
        const radio3 = document.getElementById('section3');
        const radio4 = document.getElementById('section4');
        const radio5 = document.getElementById('section5');


        radio0.addEventListener('click', handleRadioClick);
        radio1.addEventListener('click', handleRadioClick);
        radio2.addEventListener('click', handleRadioClick);
        radio3.addEventListener('click', handleRadioClick);
        radio4.addEventListener('click', handleRadioClick);
        radio5.addEventListener('click', handleRadioClick);

        // Cleanup: Remove event listeners when the component is unmounted
        return () => {
            radio0.removeEventListener('click', handleRadioClick);
            radio1.removeEventListener('click', handleRadioClick);
            radio2.removeEventListener('click', handleRadioClick);
            radio3.removeEventListener('click', handleRadioClick);
            radio4.removeEventListener('click', handleRadioClick);
            radio5.removeEventListener('click', handleRadioClick);
        };
    }, []);


    return (
        <div>
            <DecodedHTMLComponent data={data} />
        </div>
    );
};

export const QuestionViewHalf = ({ Next, question, status, sectionTitle, setConclude, questionIndex, sectionOrder, name, setquestionIndex }) => {
    const [modal, setModal] = useState(false)
    const [modalConclude, setModalConclude] = useState(false)

    const [modalData, setModalData] = useState({})

    const openModal = (e) => {
        setModal(true)
        setModalData(e)
    }

    const closeModal = () => {
        setModal(false)
    }

    const openModalConclude = (e) => {
        setModalConclude(true)
        setModalData(e)
    }

    const closeModalConclude = () => {
        setModalConclude(false)
        setConclusion(1)
    }

    // console.log(question)

    const [pages, setPages] = useState(true)

    // ______________________ for unseem content ______________________

    // const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    // const [hasScrollbar, setHasScrollbar] = useState(false);
    // const scrollContainerRef = useRef(null);

    // useEffect(() => {
    //     const checkScrollbar = () => {
    //         const scrollContainer = scrollContainerRef?.current;

    //         if (scrollContainer) {
    //             setHasScrollbar(scrollContainer?.scrollHeight > scrollContainer?.clientHeight);
    //         }
    //     };

    //     // Check scrollbar on mount and resize
    //     if (question?.user_response == null) {
    //         checkScrollbar();
    //         window.addEventListener('resize', checkScrollbar);
    //     }


    //     return () => {
    //         window.removeEventListener('resize', checkScrollbar);
    //     };
    // }, [questionIndex]);

    // const handleScroll = () => {
    //     const scrollContainer = scrollContainerRef.current;

    //     if (scrollContainer) {

    //         const scrolledToBottom =
    //             Number(scrollContainer.scrollTop.toFixed(0)) + scrollContainer.clientHeight + 5 >=
    //             scrollContainer.scrollHeight;



    //         if (scrolledToBottom) {
    //             setIsScrolledToBottom(true);
    //         }
    //     }
    // };

    // ______________________ for unseem content ______________________

    const handleButtonClick = () => {
        // ___________ for unseem content ___________
        // if (hasScrollbar) {
        //     // Container has a scrollbar

        //     if (isScrolledToBottom) {
        //         // User has scrolled to the bottom
        //         // Perform any additional checks or actions here
        //         setTimeout(() => {
        //             Next()
        //         }, 500)
        //         scrollToTop()
        //     } else {
        //         // User hasn't scrolled to the bottom
        //         // alert('Please scroll to the bottom of the container before checking.');
        //         openModal({
        //             title: "Unseen Content",
        //             description: "You have not viewed the entire screen. Make sure you scroll every corner."
        //         });
        //     }
        // } else {
        //     // Container doesn't have a scrollbar
        //     setTimeout(() => {
        //         Next()
        //     }, 500)
        //     scrollToTop()
        // }
        // ___________ for unseem content ___________
        setTimeout(() => {
            Next()
        }, 500)
        // ___________ for unseem content ___________
        // scrollToTop()
        // ___________ for unseem content ___________
    };

    // ___________ for unseem content ___________
    // const scrollToTop = () => {
    //     const scrollContainer = scrollContainerRef.current;

    //     if (scrollContainer) {
    //         scrollContainer.scrollTop = 0;
    //     }
    //     setIsScrolledToBottom(false)
    // };
    // ___________ for unseem content ___________

    const time_left = useSelector((state) => state.GET_TIME_LEFT)

    const [conclusion, setConclusion] = useState(0)
    const [queryParameters] = useSearchParams()
    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    return (
        <>

            {conclusion == 0 ?
                <>
                    <div className={style.questionViewFull}>
                        <div className={style.header}>
                            <div className={style.title}>
                                <h3>Assessment name:{name} </h3>
                            </div>
                            <div className={style.timer}>
                                <div>
                                    <p> <CountdownTimer endExamAndNext={() => openModalConclude({
                                        title: "Time Expired",
                                        description: "Time has expired for this section, click OK to continue."
                                    })} text={"Time left :"} /></p>
                                    <p key={questionIndex}> <i onClick={() => setPages(!pages)} className="fa-solid fa-pager"></i> {pages ? <span>{questionIndex + 1} of {status?.sectional_status?.length > 0 ? status?.sectional_status[0]?.length : ""}</span> : ""}</p>
                                </div>
                            </div>

                        </div>
                        <div className={style.Section_title}>
                            {sectionTitle}
                        </div>
                        <div className={style.question} style={{ overflow: "hidden" }} >

                            <div className={style.questionviewHalf}>
                                <div className={style.questionviewHalf_first}
                                // ___________ for unseem content ___________
                                // ref={scrollContainerRef}
                                // onScroll={handleScroll}
                                // ___________ for unseem content ___________
                                >
                                    <h2 className={style.direction}><DecodedHTMLComponent data={question?.question?.instructions} /></h2>
                                </div>
                                <div className={style.questionviewHalf_second}>
                                    <div className={style.question_one}>
                                        <DecodedHTMLComponent data={question?.question?.question} />
                                    </div>
                                    <div className={style.answers}>
                                        <DecodedHTMLComponent data={question?.question?.answer_type} />

                                        {/* <div 
                                answerOne'>
                                <input type='radio' /> <span>A. 1,000</span>
                            </div>
                            <div className={style.answerOne}>
                                <input type='radio' /> <span>A. 1,000</span>
                            </div>
                            <div className={style.answerOne}>
                                <input type='radio' /> <span>A. 1,000</span>
                            </div>
                            <div className={style.answerOne}>
                                <input type='radio' /> <span>A. 1,000</span>
                            </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={style.bottomSection}>
                            <div className={`${style.bottomButton} ${style.pointer}`} onClick={() => handleButtonClick()} >
                                <u>N</u>ext <i className={` ${style.fa_solid} ${style.fa_arrow_right}`}></i>
                            </div>
                        </div>
                    </div>
                </> : ""
            }


            <NmatModalwithoutBackground modal={modal} closeModal={closeModal} modalData={modalData} />

            <NmatModalwithoutBackground modal={modalConclude} closeModal={closeModalConclude} modalData={modalData} />


            {conclusion == 1 ?
                <>
                    <ConcludeScreen name={selector.name} title={sectionOrder?.name} setConclude={setConclusion} groupId={sectionOrder?.id} testId={queryParameters.get("test_id")} />
                </> : ""
            }

            {conclusion == 2 ?
                <>
                    <SectionStartScreen name={selector.name} title={sectionOrder?.name} setquestionIndex={setquestionIndex} setConclude={setConclusion} sectionOrder={sectionOrder} groupId={sectionOrder?.id} time_left={sectionOrder.duration} testId={queryParameters.get("test_id")} />
                </> : ""
            }
        </>
    )
}

export const QuestionViewFull = ({ Next, question, status, sectionTitle, setConclude, questionIndex, name, sectionOrder, setquestionIndex }) => {
    const [modal, setModal] = useState(false)
    const [modalConclude, setModalConclude] = useState(false)
    const [modalData, setModalData] = useState({})

    const openModal = (e) => {
        setModal(true)
        setModalData(e)
    }

    const closeModal = () => {
        setModal(false)
    }

    const openModalConclude = (e) => {
        setModalConclude(true)
        setModalData(e)
    }

    const closeModalConclude = () => {
        setModalConclude(false)
        setConclusion(1)
    }


    const [pages, setPages] = useState(true)

    // ______________________ for unseem content ______________________

    // const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    // const [hasScrollbar, setHasScrollbar] = useState(false);
    // const scrollContainerRef = useRef(null);

    // useEffect(() => {
    //     const checkScrollbar = () => {
    //         const scrollContainer = scrollContainerRef?.current;

    //         if (scrollContainer) {
    //             setHasScrollbar(scrollContainer?.scrollHeight > scrollContainer?.clientHeight);
    //         }
    //     };

    //     // Check scrollbar on mount and resize
    //     if (question?.user_response == null) {
    //         checkScrollbar();
    //         window.addEventListener('resize', checkScrollbar);
    //     }


    //     return () => {
    //         window.removeEventListener('resize', checkScrollbar);
    //     };
    // }, [questionIndex]);

    // const handleScroll = () => {
    //     const scrollContainer = scrollContainerRef.current;

    //     if (scrollContainer) {

    //         const scrolledToBottom =
    //             Number(scrollContainer.scrollTop.toFixed(0)) + scrollContainer.clientHeight + 5 >=
    //             scrollContainer.scrollHeight;


    //         if (scrolledToBottom) {
    //             setIsScrolledToBottom(true);
    //         }
    //     }
    // };

    // ______________________ for unseem content ______________________

    const handleButtonClick = () => {
        // ______________________ for unseem content ______________________
        // if (hasScrollbar) {
        //     // Container has a scrollbar
        //     if (isScrolledToBottom) {
        //         // User has scrolled to the bottom
        //         // Perform any additional checks or actions here
        //         setTimeout(() => {
        //             Next()
        //         }, 500)
        //         scrollToTop()
        //     } else {
        //         // User hasn't scrolled to the bottom
        //         // alert('Please scroll to the bottom of the container before checking.');
        //         openModal({
        //             title: "Unseen Content",
        //             description: "You have not viewed the entire screen. Make sure you scroll every corner."
        //         });
        //     }
        // } else {
        //     // Container doesn't have a scrollbar
        //     setTimeout(() => {
        //         Next()
        //     }, 500)
        //     scrollToTop()
        // }
        // ______________________ for unseem content ______________________
        setTimeout(() => {
            Next()
        }, 500)
        // ______________________ for unseem content ______________________
        // scrollToTop()
        // ______________________ for unseem content ______________________
    };


    // const scrollToTop = () => {
    //     const scrollContainer = scrollContainerRef.current;

    //     if (scrollContainer) {
    //         scrollContainer.scrollTop = 0;
    //     }
    //     setIsScrolledToBottom(false)
    // };

    const [conclusion, setConclusion] = useState(0)
    const [queryParameters] = useSearchParams()
    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    return (
        <>
            {conclusion == 0 ?
                <>
                    <div className={style.questionViewFull}>
                        <div className={style.header}>
                            <div className={style.title}>
                                <h3>Assessment name: {name}  </h3>
                            </div>
                            <div className={style.timer}>
                                <div>
                                    <p> <CountdownTimer endExamAndNext={() => openModalConclude({
                                        title: "Time Expired",
                                        description: "Time has expired for this section, click OK to continue."
                                    })} text={"Time left :"} /></p>
                                    <p key={questionIndex}> <i onClick={() => setPages(!pages)} className="fa-solid fa-pager"></i> {pages ? <span>{questionIndex + 1} of {status?.sectional_status?.length > 0 ? status?.sectional_status[0]?.length : ""}</span> : ""}</p>
                                </div>
                            </div>
                        </div>

                        <div className={style.Section_title}>
                            {sectionTitle}
                        </div>

                        <div className={style.question}
                        // ______________________ for unseem content ______________________
                        // ref={scrollContainerRef} onScroll={handleScroll}
                        // ______________________ for unseem content ______________________
                        >

                            <div className={style.question_one}>
                                <DecodedHTMLComponent data={question?.question?.question} />
                            </div>
                            <div className={style.answers}>
                                <DecodedHTMLComponent data={question?.question?.answer_type} />
                            </div>
                        </div>

                        <div className={style.bottomSection}>
                            <div className={`${style.bottomButton} ${style.pointer}`} onClick={() => handleButtonClick()} >
                                <u>N</u>ext <i className={` ${style.fa_solid} ${style.fa_arrow_right}`}></i>
                            </div>
                        </div>


                    </div>
                </> : ""
            }



            <NmatModalwithoutBackground modal={modal} closeModal={closeModal} modalData={modalData} />

            <NmatModalwithoutBackground modal={modalConclude} closeModal={closeModalConclude} modalData={modalData} />

            {conclusion == 1 ?
                <>
                    <ConcludeScreen name={selector.name} title={sectionOrder?.name} setConclude={setConclusion} groupId={sectionOrder?.id} testId={queryParameters.get("test_id")} />
                </> : ""
            }

            {conclusion == 2 ?
                <>
                    <SectionStartScreen name={selector.name} title={sectionOrder?.name} setquestionIndex={setquestionIndex} setConclude={setConclusion} sectionOrder={sectionOrder} groupId={sectionOrder?.id} time_left={sectionOrder.duration} testId={queryParameters.get("test_id")} />
                </> : ""
            }

        </>
    )
}

export const Instructions = ({ data }) => {
    const [queryParameters] = useSearchParams()
    const dispatch = useDispatch()

    const [screen, setScreen] = useState(0)

    const [htmlData, setHtmlData] = useState({})

    const [agreed, setAgreed] = useState(0)
    const [disagreeMessage, setDisagreeMessage] = useState("")

    const confirmation = (e) => {
        setAgreed(e)
    }

    const SubmitInstruction = async (status, screenId, order) => {

        const url = SubmitNmatInstructionUrl(screenId)
        const body = status ? {
            has_submit: status,
            section_ordering: order
        } : {
            has_submit: status,
        }
        const data = await CallWithAuth("POST", url, body)
        // console.log("question",data)
        if (data.res.status && data.res.data.has_submit) {
            dispatch(GetInfo(queryParameters.get("test_id")))

            // setQuestionView(true)

        }
    }
    const Next = () => {
        setDisagreeMessage("")
        setLoader(false)
        if (data[screen].has_confirmation) {
            if (agreed == 0) {
                window.scrollTo(0, 0);

                setLoader(true)
            } else if (agreed == 1) {
                setScreen(screen + 1)
                SubmitInstruction(false, data[screen].id)
                // API CALL for second 
            }
            else {
                setDisagreeMessage("You clicked the 'I disagree' button, which indicated that you do not accept the NMAT by GMAC Nondisclosure agreement and General terms of use. If you click the ' I disagree ' button on this screen again, you will not be allowed to begin the exam and you will forfeit your exam fee.")

                setLoader(true)
            }
        } else {
            if (data[screen].has_submit) {
                // api call for third 

                if (selectedIndex == null) {
                    openModal({
                        title: "Selection Required",
                        description: "Please make your selection to continue."
                    })
                    setLoader(true)
                } else {
                    SubmitInstruction(true, data[screen]?.id, htmlData?.sequence[selectedIndex]?.order)
                }
            } else {
                setScreen(screen + 1)
                SubmitInstruction(false, data[screen].id)
                // API CALL for first 
            }
        }
    }

    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState({})

    const openModal = (e) => {
        setModal(true)
        setModalData(e)
    }

    const TimerEnd = () => {
        SubmitInstruction(true, data[screen].id, htmlData?.sequence[0].order)
    }

    const closeModal = () => {
        setModal(false)
    }

    const [selectedIndex, setSelectedIndex] = useState(null)

    // console.log(htmlData[2]?.section_sequence[selectedIndex].section_ordering)

    const Instructions = async (id) => {
        const url = GetNmatInstructionUrl(id)
        const data = await CallWithAuth("GET", url)
        if (data.res.status == 200) {
            setHtmlData(data.res.data)
            dispatch(PutTime({ time_left: data.res.data.time }))
            setLoader(true)
        }
    }

    useEffect(() => {
        Instructions(data[screen].id)
    }, [screen])


    const [loader, setLoader] = useState(true)
    const [id, setId] = useState(0)

    const Repeat = () => {
        setId(htmlData?.time)
    }

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [])

    // const [scrollTouched, setScrollTouched] = useState(false);

    // const handleScroll = () => {
    //     // Update the scrollTouched flag when the user scrolls
    //     setScrollTouched(true);
    // };

    // const handleScrollDefault = () => {
    //     // Update the scrollTouched flag when the user scrolls
    //     setScrollTouched(false);
    // };

    // const handleButtonClick = () => {
    //     // Check if the user has scrolled to the bottom
    //     const scrolledToBottom =
    //         window.innerHeight + window.scrollY >= document.body.offsetHeight;

    //     // If not scrolled to the bottom and the scrollbar hasn't been touched, show an alert
    //     if (!scrolledToBottom && !scrollTouched) {
    //         openModal({
    //             title: "Unseen Content",
    //             description: "You have not viewed the entire screen. Make sure you scroll every corner."
    //         })
    //     } else {
    //         // If scrolled to the bottom or the scrollbar has been touched, update the state
    //         Next()
    //         handleScrollDefault()
    //     }
    // };


    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [scrollTouched]);



    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [hasScrollbar, setHasScrollbar] = useState(false);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const checkScrollbar = () => {
            const scrollContainer = scrollContainerRef?.current;
            if (scrollContainer) {
                setHasScrollbar(scrollContainer?.scrollHeight > scrollContainer?.clientHeight);
            }
        };

        // Check scrollbar on mount and resize
        if (loader) {
            checkScrollbar();
            window.addEventListener('resize', checkScrollbar);
        }


        return () => {
            window.removeEventListener('resize', checkScrollbar);
        };
    }, [loader]);

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {

            const scrolledToBottom =
                Number(scrollContainer.scrollTop.toFixed(0)) + scrollContainer.clientHeight + 5 >=
                scrollContainer.scrollHeight;


            if (scrolledToBottom) {
                setIsScrolledToBottom(true);
            }
        }
    };

    const handleButtonClick = () => {
        if (hasScrollbar) {
            // Container has a scrollbar
            if (isScrolledToBottom) {
                // User has scrolled to the bottom
                // Perform any additional checks or actions here
                setTimeout(() => {
                    Next()
                }, 500)
                scrollToTop()
            } else {
                // User hasn't scrolled to the bottom
                // alert('Please scroll to the bottom of the container before checking.');
                openModal({
                    title: "Unseen Content",
                    description: "You have not viewed the entire screen. Make sure you scroll every corner."
                });
            }
        } else {
            // Container doesn't have a scrollbar
            setTimeout(() => {
                Next()
            }, 500)
            scrollToTop()
        }
    };


    const scrollToTop = () => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
        setIsScrolledToBottom(false)
    };


    return (
        <>


            {loader ? <>


                <div className={style.NmatHeaderDiv}>
                    <img src='https://nmat.excelindia.com/TNA/TestPlayer/Themes/SkyBlue/Images/NMATlogo.png' />


                    {data[screen]?.has_submit ? <div className={style.time_remaining}>
                        <div>
                            <CountdownTimer endExamAndNext={TimerEnd} text={"Time Remaining"} />
                        </div>
                    </div> : ""}

                    {data[screen].has_confirmation ? <div className={style.time_remaining}>
                        <div>
                            <CountdownTimer endExamAndNext={() => Repeat()} text={"Time Remaining"} />
                        </div>
                    </div> : ""}

                </div>


                <div
                    ref={scrollContainerRef}
                    className={style.contentBase64}
                    // style={{
                    //     height: '100vh', // Set the desired height of your scrollable container
                    //     overflow: 'auto',
                    //     border: '1px solid #ccc',
                    // }}
                    onScroll={handleScroll}
                >

                    <div className={style.InstructionOne}>
                        <img src='https://nmat.excelindia.com/TNA/TestPlayer/Themes/SkyBlue/Images/NMATlogo.png' />
                    </div>

                    {disagreeMessage &&
                        <div className={style.disagree_div}>
                            <h1 className={style.disagree}>{disagreeMessage}</h1>
                        </div>}

                    {data[screen]?.has_submit ?
                        <ExamSectionOrder data={htmlData?.html_content} setSelectedIndex={setSelectedIndex} />
                        :
                        <>
                            {data[screen].has_confirmation ? <>

                                <div className={style.DecodedHtml}>
                                    <DecodedHTMLComponent data={htmlData?.html_content} />
                                </div>

                                <div className={style.agreeSelection}>
                                    <input type="radio" id="agropt" className={style.pointer} name="optradio" onClick={() => confirmation(1)} />
                                    I Agree
                                    <br />
                                    <input type="radio" name="optradio" id="agropt" onClick={() => confirmation(2)} />
                                    I Disagree
                                </div>

                            </> : <>
                                <div className={style.DecodedHtml}>
                                    <h1>welcome, {Cookies.get('firstName')} !</h1>
                                    <DecodedHTMLComponent data={htmlData?.html_content} />
                                </div>

                            </>}
                        </>
                    }


                </div>


                <div className={style.bottomSection}>
                    <div className={`${style.bottomButton} ${style.pointer}`} onClick={() => handleButtonClick()} >
                        <u>N</u>ext <i className={` ${style.fa_solid} ${style.fa_arrow_right}`}></i>
                    </div>
                </div>

                <NmatModalwithoutBackground modal={modal} closeModal={closeModal} modalData={modalData} />

            </> : ""}

        </>
    )
}

export const ConcludeScreen = ({ title, setConclude, groupId, testId, name }) => {

    const dispatch = useDispatch()
    const selector = useSelector((state) => state.GET_INFO_REDUCER)
    // console.log(selector?.group_details[selector?.group_details.length - 1]?.id)

    const [LastScreen, setLastScreen] = useState(false)
    const Next = async () => {
        // try{
        const url = NmatSectionSubmitUrl(testId, groupId)
        const body = ""
        const data = await CallWithAuth("POST", url, body)
        // console.log(data)
        if (data.res.status === 200 || data.res.status === 201 || data.res.status === 202) {
        } else {
            dispatch(ApiStatusforNon200(true))
        }


        if (data.res.status == 201) {
            if (selector?.group_details[selector?.group_details.length - 1]?.id == groupId) {
                // window.close()
                // setLastScreen(true)
                if(selector.exam_finished_message == null){
                    const BASE_URL = process.env.REACT_APP_API_KEY;
                    var play_url = "https://play.imsindia.com/";
    
                    if (BASE_URL.includes("staging")) {
                        play_url = "https://stagingplay.imsindia.com/";
                    }
                    window.location.replace(`${play_url}full-review/?test_id=${testId}`);
                }else{
                    setConclude(3)
                }
               
                // https://play.imsindia.com/full-review/?test_id=1678444865044
            } else {
                dispatch(GroupSuccessed(data.res.data))
                setConclude(2)
            }

        }
        // }catch(e){
        //     console.log("error",e)
        //     dispatch(ApiStatusforNon200(true))
        // }

    }

    return (
        <>
            {/* <div className={style.questionViewFull}>
                <div className={style.header}>
                    <div className={style.title}>
                        <h3>Assessment name: NMAT by GMAC Official Practice Exam 1 </h3>
                    </div>

                </div>
                <div className={style.question}>
                    <div className={style.title}>

                    </div>
                    <div className={style.conclude_text}>
                        This screen conclude {title} section.
                    </div>
                    <div className={style.bottomSection}>
                        <div className={style.bottomButton} onClick={() => Next()} >
                            <u>N</u>ext <i className={` ${style.fa_solid} ${style.fa_arrow_right}`}></i>
                        </div>
                    </div>
                </div>
            </div> */}
            {LastScreen ?
                <div className={style.exitScreen}>
                    Exam Completed, close the window
                </div>
                :
                <div className={style.questionViewFull}>
                    <div className={style.header}>
                        <div className={style.title}>
                            <h3>Assessment name: {name}</h3>
                        </div>

                    </div>
                    <div className={style.question}>
                        <div className={style.title}>

                        </div>
                        <div className={style.conclude_text}>
                            This concludes the {title} section.
                        </div>
                        <div className={style.bottomSection}>
                            <div className={`${style.bottomButton} ${style.pointer}`} onClick={() => setTimeout(() => {
                                Next()
                            }, 500)} >
                                <u>N</u>ext <i className={` ${style.fa_solid} ${style.fa_arrow_right}`}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export const SectionStartScreen = ({ title, setConclude, groupId, testId, time_left, sectionOrder, name, setquestionIndex }) => {
    const selector = useSelector((state) => state.GET_INFO_REDUCER)


    const dispatch = useDispatch()
    const Next = () => {
        dispatch(GetInfo(testId))
        setConclude(0)
        setquestionIndex(-1)
    }



    const active_group = selector.group_details.find(group => group.status == 1)


    // console.log("selector-->", selector, active_group)

    return (
        <>
            <div className={style.questionViewFull}>
                <div className={style.header}>
                    <div className={style.title}>
                        <h3>Assessment name: {name} </h3>
                    </div>

                </div>
                <div className={style.question}>
                    <div className={style.title}>

                    </div>
                    <div className={style.startScreen_text}>
                        <p className={style.startScreen_title}>{active_group.name}</p>
                        <p>There are {selector.is_adaptive ? 36 : active_group.section_detail[0].ct_ids.length} exam questions in this section.</p>
                        <p>You have {time_left} minutes to complete this section.</p>
                        <p>Click on the <u>N</u>ext button to begin</p>
                    </div>
                    <div className={style.bottomSection}>
                        <div className={`${style.bottomButton} ${style.pointer}`} onClick={() => setTimeout(() => {
                            Next()
                        }, 500)} >
                            <u>N</u>ext <i className={` ${style.fa_solid} ${style.fa_arrow_right}`}></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const QuestionView = () => {
    const selector = useSelector((state) => state.GET_INFO_REDUCER)
    const [queryParameters] = useSearchParams()

    // const BASE_URL = process.env.REACT_APP_API_KEY
    // const connServer = BASE_URL + "socket/establish-nmat-connection/"

    // useEffect(() => {
    //     const socket = new io(connServer)
    //     return () => {
    //         socket.close();
    //     }
    // }, [])



    // const [time_left, setTimeLeft] = useState({})
    const [status, setStatus] = useState({})

    const Next = () => {
        const answer = window.parse_answer.extractAnswers();
        // console.log(answer)
        if (answer[0][0] == null) {
            openModal({
                title: "Answer Required",
                description: "You cannot continue with this question unanswered."
            })
        } else {
            ImsQuestionSubmitCall(answer)
        }

    }

    const [adaptive, setAdaptive] = useState(false)
    const [adaptiveLength, setAdativelength] = useState(null)
    const AdaptiveCheck = (status, value) => {
        setAdaptive(status)
        setAdativelength(value)
    }

    useEffect(() => {
        if (adaptive) {
            const selectedgroup = selector.group_details.find(group => group.id === sectionOrder.id);
            if (adaptiveLength != null) {
                if (selectedgroup.section_detail[0]?.ct_ids.length == adaptiveLength) {
                    setConclude(1)
                    setAdaptive(false)
                    setAdativelength(null)
                }
            }
        }
    }, [selector])


    const ImsQuestionSubmitCall = async (answer) => {
        openModalLoader()
        if (sectionOrder?.section_detail[0]?.ct_ids[questionIndex]) {
            const url = NmatQuestionSubmitUrl(sectionOrder?.section_detail[0]?.ct_ids[questionIndex])
            // console.log(url, answer, JSON.stringify(answer).replace(/"/g,""))
            const body = {
                "object_id": sectionOrder?.section_detail[0]?.ct_ids[questionIndex],
                "user_response": JSON.stringify(answer).replace(/"/g, ""),
                "user_response_ui": answer
            }

            const data = await CallWithAuth("POST", url, body)
            // console.log(data)
            if (data.res.status == 201) {
                if ((questionIndex + 1) >= sectionOrder?.section_detail[0]?.ct_ids.length) {
                    if (selector?.is_adaptive) {
                        dispatch(GetInfo(queryParameters.get("test_id")))
                        closeModalLoader()
                        window.scrollTo(0, 0);
                        AdaptiveCheck(true, sectionOrder?.section_detail[0]?.ct_ids.length)
                    }
                    else {
                        setConclude(1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    }
                } else {
                    AdaptiveCheck(false, null)
                    setquestionIndex(questionIndex + 1)
                    closeModalLoader()
                    window.scrollTo(0, 0);
                }
            }
        }
    }

    const [question, setQuestion] = useState({})

    const [questionIndex, setquestionIndex] = useState(0)
    const [sectionOrder, setSectionOrder] = useState({})


    const [modals, setModals] = useState(false)




    const [questionStatusLoaded, setQuestionStatusLoaded] = useState(false)
    const GetQuestion = async (id) => {
        const url = NmatQuestionViewUrl(id)
        const data = await CallWithAuth("GET", url)

        if (data.res.status == 200) {
            setQuestion(data.res.data)
            setQuestionStatusLoaded(true)
        }

    }

    const GetQuestionStatus = async (testId) => {
        const url = NmatQuestionStatusViewUrl(testId)
        const data = await CallWithAuth("GET", url)
        if (data.res.status == 200) {
            const current_Question = data?.res?.data?.sectional_status[0]?.findIndex(
                (item) => item === 1 || item === 2
            )
            // if(current_Question >= 0){
            // console.log("current_Question", current_Question)
            setquestionIndex(current_Question)
            setStatus(data?.res?.data)

            // }

            // setQuestion(data.res.data.question)
        }
    }

    const dispatch = useDispatch()

    const Time_left = async (current_section) => {
        // console.log(current_section)
        // const url = NmatTimeLeft(queryParameters.get("test_id"), current_section.id)
        // const data = await CallWithAuth("GET", url)
        // // console.log(data)
        // if (data?.res?.status == 200) {
        //     setTimeLeft(data?.res?.data)
        // }

    }




    const [conclude, setConclude] = useState(0)
    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState({})

    const openModal = (e) => {
        setModal(true)
        setModalData(e)
    }

    const closeModal = () => {
        setModal(false)
    }

    // useEffect(() => {
    //     // console.log("sectionOrder", sectionOrder)
    //     if (Object.keys(sectionOrder).length > 0) {
    //         GetQuestion(sectionOrder.section_detail[0]?.ct_ids[questionIndex])
    //         GetQuestionStatus(queryParameters.get("test_id"))
    //     }

    // }, [sectionOrder, questionIndex])


    const [isPageHidden, setIsPageHidden] = useState(false);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsPageHidden(document.hidden);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    useEffect(() => {
        if (isPageHidden == true) {
            setModals(true)
        } else {
            setModals(false)
            // console.log("sectionOrder", sectionOrder)
            if (Object.entries(sectionOrder).length > 0) {
                dispatch(GetTimeleft(queryParameters.get("test_id"), sectionOrder?.id))
            }
        }
    }, [isPageHidden])

    useEffect(() => {
        if (questionIndex >= 0) {
            if (Object.keys(sectionOrder).length > 0) {
                // console.log("sectionOrders", selector, sectionOrder.section_detail[0]?.ct_ids[questionIndex], selector?.group_details.find(group => group.status == 1))
                // const active_group = selector?.group_details.find(group => group.status == 1)?.section_detail[0]
                // console.log("active_group", active_group?.ct_ids[0])

                // console.log("questionIndex", questionIndex)
                if (questionIndex >= 0) {
                    GetQuestion(sectionOrder.section_detail[0]?.ct_ids[questionIndex])
                    setTimeout(() => {
                        GetQuestionStatus(queryParameters.get("test_id"))
                    }, [1000])
                    // GetQuestionStatus(queryParameters.get("test_id"))
                    // dispatch(GetTimeleft(queryParameters.get("test_id"), sectionOrder?.id))
                    // setTimeout(()=>{
                    //     dispatch(GetTimeleft(queryParameters.get("test_id"),sectionOrder?.id ))
                    // },1000)

                } else {
                    GetQuestion(sectionOrder.section_detail[0]?.ct_ids[0])
                    setTimeout(() => {
                        GetQuestionStatus(queryParameters.get("test_id"))
                    }, [1000])
                    // dispatch(GetTimeleft(queryParameters.get("test_id"), sectionOrder?.id))
                    // setTimeout(()=>{
                    //     dispatch(GetTimeleft(queryParameters.get("test_id"),sectionOrder?.id ))
                    // },1000)
                }

            }
        } else {
            setquestionIndex(0)
        }


    }, [sectionOrder, questionIndex])


    useEffect(() => {
        if (selector?.group_details.length > 0) {
            const current_section = selector?.group_details?.find(
                (group) => group.status === 0 || group.status === 1
            )
            if (current_section === undefined) {
                // alert("Exam is completed please, reset the ID")
                setConclude(3)
            } else {
                setSectionOrder(current_section)
                // setTimeout(()=>{
                //     Time_left(current_section)
                // },1000)
                // Time_left(current_section)
            }

        }
    }, [selector])



    // MODAL 
    const [modalLoader, setModalLoader] = useState(false)


    const openModalLoader = (e) => {
        setModalLoader(true)
    }

    const closeModalLoader = () => {
        setModalLoader(false)
    }

    // console.log("questionStatusLoaded",questionStatusLoaded)

    // ___________ PING API ___________
    useEffect(() => {
        if (questionStatusLoaded) {
            const intervalId = setInterval(() => {

                dispatch(Ping(queryParameters.get("test_id"))); // Dispatch the action


            }, 10000); // 10 seconds in milliseconds

            return () => clearInterval(intervalId); // Clean up the interval on component unmount
        }
    }, [questionStatusLoaded]);



    // const [scrollTouched, setScrollTouched] = useState(false);

    // const handleScroll = () => {
    //     // Update the scrollTouched flag when the user scrolls
    //     setScrollTouched(true);
    // };

    // const handleScrollDefault = () => {
    //     // Update the scrollTouched flag when the user scrolls
    //     setScrollTouched(false);
    // };

    // const handleButtonClick = () => {
    //     // If the scrollbar hasn't been touched at the bottom, show an alert
    //     if (!scrollTouched) {
    //         openModal({
    //             title: "Unseen Content",
    //             description: "You have not viewed the entire screen. Make sure you scroll every corner."
    //         });
    //     } else {
    //         // If scrolled to the bottom, update the state
    //         Next();
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [scrollTouched]);


    // console.log("time_left",time_left)


    const ScoreCard = () => {
        const BASE_URL = process.env.REACT_APP_API_KEY;
        var play_url = "https://play.imsindia.com/";

        if (BASE_URL.includes("staging")) {
            play_url = "https://stagingplay.imsindia.com/";
        }
        window.location.replace(`${play_url}full-review/?test_id=${queryParameters.get("test_id")}`);
    }

    return (
        <>
            {conclude == 0 ?
                <>

                    {question?.user_response == null ?
                        <>

                            <div>
                                {question?.question?.instructions ?
                                    <QuestionViewHalf name={selector.name} Next={Next} question={question} setquestionIndex={setquestionIndex} status={status} sectionTitle={sectionOrder?.name} sectionOrder={sectionOrder} setConclude={setConclude} questionIndex={questionIndex} />
                                    :
                                    <QuestionViewFull name={selector.name} Next={Next} question={question} setquestionIndex={setquestionIndex} status={status} sectionTitle={sectionOrder?.name} sectionOrder={sectionOrder} setConclude={setConclude} questionIndex={questionIndex} />
                                }
                            </div>
                        </> : ""}

                    <NmatModalwithoutBackground modal={modal} closeModal={closeModal} modalData={modalData} />

                    <Loader modal={modals} player={0} />

                </>
                :
                ""
            }

            {conclude == 1 ?
                <>
                    <ConcludeScreen name={selector.name} title={sectionOrder?.name} setConclude={setConclude} groupId={sectionOrder?.id} testId={queryParameters.get("test_id")} />
                </> : ""
            }

            {conclude == 2 ?
                <>
                    <SectionStartScreen name={selector.name} title={sectionOrder?.name} setConclude={setConclude} sectionOrder={sectionOrder} setquestionIndex={setquestionIndex} groupId={sectionOrder?.id} time_left={sectionOrder.duration} testId={queryParameters.get("test_id")} />
                </> : ""}

            {
                conclude == 3 ? <>
                    <div className={style.center_content}>
                        <div style={{ textAlign: "center" }}>
                            {selector.exam_finished_message == null ?
                                <>
                                    Exam Completed
                                    <p>Click on Score-card to see the Scores  </p>
                                    <button className={style.view_result} onClick={() => ScoreCard()}>ScoreCard</button>
                                </> : selector.exam_finished_message}

                        </div>

                    </div>
                </> : ""
            }

            <Loader modal={modalLoader} player={0} />
        </>
    )
}

const Nmat = () => {

    const dispatch = useDispatch()
    const [queryParameters] = useSearchParams()
    const [PlayerStatus, setPlayerStatus] = useState({})

    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    useEffect(() => {
        if (Object.keys(selector).length > 0) {
            setPlayerStatus(selector)
        }
    }, [selector])

    // const [questionView, setQuestionView] = useState(false)

    // useEffect(() => {
    //     if (Object.keys(PlayerStatus).length > 0) {
    //         const index = PlayerStatus?.instructions.length
    //         if (PlayerStatus?.instructions[index - 1].status == 2) {
    //             setQuestionView(true)
    //         }
    //     }

    // }, [PlayerStatus])

    const isMobileView = useMobileCheck()

    if (!isMobileView) {

        if (Object.keys(PlayerStatus).length > 0) {
            const index = PlayerStatus?.instructions.length
            if (PlayerStatus?.instructions[index - 1].status == 2) {
                return <div>
                    <QuestionView />
                </div>
            } else {
                return <div className={style.Instruction}>
                    {Object.keys(PlayerStatus).length > 0 ? <Instructions data={PlayerStatus?.instructions}
                    // setQuestionView={setQuestionView} 
                    /> : ""}
                </div>
            }
        }

        // return <div>
        //     {questionView ?
        //         <div>
        //             <QuestionView />
        //         </div>
        //         :
        //         <div className={style.Instruction}>
        //             {Object.keys(PlayerStatus).length > 0 ? <Instructions data={PlayerStatus?.instructions} setQuestionView={setQuestionView} /> : ""}
        //         </div>
        //     }

        // </div>

    }
    return <NotCompatible />
}

export default Nmat