import { useEffect, useState } from "react";

function useMobileCheck() {
    const mobileWidthThreshold = 714;
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
  
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < mobileWidthThreshold);
    };

    useEffect(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
        return () => {
        window.removeEventListener('resize', checkIsMobile);
      };
    }, []);
  
    return isMobile;
  }
  
  export default useMobileCheck;
  