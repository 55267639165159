import React, { useEffect, useState } from 'react'
import "./style.css"
import { DecodedHTMLComponent } from '../../Components/Utils/DecodeHTMLComponent'
import { useSelector } from 'react-redux'
import { CallWithAuth } from '../../action/apiActions'
import { QuestionStatusViewUrl, QuestionSubmitUrl, QuestionViewUrl, SectionSubmitUrl, TimeLeft } from '../../action/apiPath'
import { useSearchParams } from 'react-router-dom'
import { CountdownTimer } from '../../Components/Utils/NmatTimerCoundown'
import { Loader } from '../../Components/Modals/Loader'
import { NmatModalwithoutBackground } from '../../Components/Modals/NmatModalwithoutBackground'

import style from '../Atma/atmaStyle.module.css'

const Instructions = ({ setQuestionView, instruction }) => {
    const QuestionStart = () => {
        setQuestionView(true)
    }
    return (
        <>
            <div className={style.home}>
                <header className={style.home_header}>
                    ATMA SAMPLE TEST
                </header>

                <div className={style.container}>

                    <div className={style.left_panel}>
                    </div>
                    <div className={style.right_panel}>
                        <div className={style.card}>
                            <div className={style.img_div}>
                                <img className={style.card_img}
                                    src="https://mocktest.atmaaims.com/images/mocktest.jpg"
                                    alt="mocktest-image" height={180} width={1177}
                                ></img>
                            </div>

                            <div className={style.student_instruction}>
                                <DecodedHTMLComponent data={instruction?.html_content} />

                                <div className={style.images}>
                                    <img className={style.test_img} src="https://mocktest.atmaaims.com/images/exam.jpg" alt="sample-image"></img>
                                    <img className={style.test_img} src="https://mocktest.atmaaims.com/images/exam.jpg" alt="sample-image"></img>
                                    <img className={style.test_img} src="https://mocktest.atmaaims.com/images/exam.jpg" alt="sample-image"></img>
                                    <img className={style.test_img} src="https://mocktest.atmaaims.com/images/exam.jpg" alt="sample-image"></img>
                                </div>

                                <div className={style.sample_tests}>
                                    <a onClick={() => QuestionStart()} className={style.no_of_tests}>Sample Test 1</a>
                                    <a className={` ${style.no_of_tests} ${style.isDisabled}`} >Sample Test 2</a>
                                    <a className={` ${style.no_of_tests} ${style.isDisabled}`}>Sample Test 3</a>
                                    <a className={` ${style.no_of_tests} ${style.isDisabled}`}>Sample Test 4</a>
                                </div>
                            </div>

                        </div>


                        <div className={style.text_centre}>
                            <center>
                                <small className={style.copyright}>Copyright © atmaaims.com 2018</small>
                            </center>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}


const QuestionView = () => {

    const [queryParameters] = useSearchParams()

    const [time_left, setTimeLeft] = useState({})
    const [status, setStatus] = useState({})

    const [question, setQuestion] = useState({})

    const [questionIndex, setquestionIndex] = useState(0)
    const [sectionOrder, setSectionOrder] = useState({})


    const Time_left = async (current_section) => {
        // console.log(current_section)
        const url = TimeLeft(queryParameters.get("test_id"), current_section.id)
        const data = await CallWithAuth("GET", url)
        // console.log(data)
        if (data?.res?.status == 200) {
            setTimeLeft(data?.res?.data)
        }

    }

    const GetQuestion = async (id) => {
        openModalLoader()

        setTimeout(async () => {
            const url = QuestionViewUrl(id)
 
            const data = await CallWithAuth("GET", url)
          
            if (data.res.status == 200) {
                setQuestion(data.res.data)
                GetQuestionStatus(queryParameters.get("test_id"))
                closeModalLoader()

                // if (data.res.data.user_response != null) {

                // }

                if (data.res.data.user_response != null && data.res.data.id != null) {
                    setTimeout(() => {
                        window.parse_answer.insertAnswers(data.res.data.user_response, `exam_question_${data.res.data.id}`)
                    }, 500);
                }

            }
        }, 1000);



    }

    const GetQuestionStatus = async (testId) => {
        const url = QuestionStatusViewUrl(testId)
        const data = await CallWithAuth("GET", url)
        if (data.res.status == 200) {
 
            // const current_Question = data?.res?.data?.sectional_status[0]?.findIndex(
            //     (item) => item === 1 || item === 2
            // )
            // console.log(current_Question)
            // if(current_Question >= 0){
            // setquestionIndex(current_Question)
            setStatus(data?.res?.data)
            // }

            // setQuestion(data.res.data.question)
        }
    }



    // useEffect(() => {
    //     console.log("sectionOrder", sectionOrder)
    //     if (Object.keys(sectionOrder).length > 0) {
    //         if (questionIndex >= 0) {
    //             GetQuestion(sectionOrder.section_detail[0]?.ct_ids[questionIndex])
    //             GetQuestionStatus(queryParameters.get("test_id"))
    //         } else {
    //             GetQuestion(sectionOrder.section_detail[0]?.ct_ids[0])
    //             GetQuestionStatus(queryParameters.get("test_id"))
    //         }

    //     }

    // }, [sectionOrder, questionIndex])

    const [questionGet, setQuestionGet] = useState(false)

    useEffect(() => {
       
        if (Object.keys(sectionOrder).length > 0) {
            if (questionIndex >= 0) {
                GetQuestion(sectionOrder?.ct_ids[questionIndex])
            }
        }
    }, [questionIndex, questionGet])

    useEffect(() => {


        if (questionIndex == 0) {
            setQuestionGet(!questionGet)
        } else {
            setquestionIndex(0)
        }


    }, [sectionOrder])

    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    useEffect(() => {
        // if (selector?.group_details.length > 0) {
        //     const current_section = selector?.group_details?.find(
        //         (group) => group.status === 0 || group.status === 1
        //     )
        Time_left(selector?.group_details[0])
        setSectionOrder(selector?.group_details[0]?.section_detail[0])
        GetQuestion(selector?.group_details[0]?.section_detail[0]?.ct_ids[questionIndex])
        // GetQuestionStatus(queryParameters.get("test_id"))
        // }
    
    }, [selector])



    // MODAL
    const [modalLoader, setModalLoader] = useState(false)


    const openModalLoader = (e) => {
        setModalLoader(true)
    }

    const closeModalLoader = () => {
        setModalLoader(false)
    }


  

    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState({})

    const openModal = (e) => {
        setModal(true)
        setModalData(e)
    }

    const closeModal = () => {
        setModal(false)
    }

    const SaveNext = async () => {
        const answer = window.parse_answer.extractAnswers();

        if (answer[0][0] == null) {
            openModalLoader()
            // openModal({
            //     title: "Answer Required",
            //     description: "You cannot continue with this question unanswered."
            // })
            if ((questionIndex + 1) >= sectionOrder?.ct_ids.length) {
                setquestionIndex(0)
            } else {
                setquestionIndex(questionIndex + 1)
            }

            closeModalLoader()
        } else {
            openModalLoader()
            if (sectionOrder?.ct_ids[questionIndex]) {
                const url = QuestionSubmitUrl(sectionOrder?.ct_ids[questionIndex])
                const body = {
                    "object_id": sectionOrder?.ct_ids[questionIndex],
                    "user_response": `"[[${answer}]]"`,
                    "user_response_ui": answer
                }
                const data = await CallWithAuth("POST", url, body)
                if (data.res.status == 201) {
                    if ((questionIndex + 1) >= sectionOrder?.ct_ids.length) {
                        setquestionIndex(0)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    } else {
                        setquestionIndex(questionIndex + 1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    }
                }
            }
        }

    }

    const SavePrevious = async () => {
        const answer = window.parse_answer.extractAnswers();

        if (answer[0][0] == null) {
            openModalLoader()
            // openModal({
            //     title: "Answer Required",
            //     description: "You cannot continue with this question unanswered."
            // })
            if ((questionIndex == 0)) {
                setquestionIndex(sectionOrder?.ct_ids.length - 1)
            } else {
                setquestionIndex(questionIndex - 1)
            }

            closeModalLoader()
        } else {
            openModalLoader()
            if (sectionOrder?.ct_ids[questionIndex]) {
                const url = QuestionSubmitUrl(sectionOrder?.ct_ids[questionIndex])
                const body = {
                    "object_id": sectionOrder?.ct_ids[questionIndex],
                    "user_response": `"[[${answer}]]"`,
                    "user_response_ui": answer
                }
                const data = await CallWithAuth("POST", url, body)
                if (data.res.status == 201) {
                    if ((questionIndex == 0)) {
                        setquestionIndex(sectionOrder?.ct_ids.length - 1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    } else {
                        setquestionIndex(questionIndex - 1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    }
                }
            }
        }

    }

    const SubmitTest = async () => {
        const testId = queryParameters.get("test_id")
        const groupId = selector?.group_details[0]?.id
        const url = SectionSubmitUrl(testId, groupId)
        const body = ""
        const data = await CallWithAuth("POST", url, body)
      
        if (data.res.status == 201) {
            // if (selector?.group_details[selector?.group_details.length - 1]?.id == groupId) {
            //     // window.close()
            //     setLastScreen(true)
            // } else {
            //     dispatch(GetInfo(testId))
            //     setConclude(2)
            // }
            // alert("done")
            const BASE_URL = process.env.REACT_APP_API_KEY;
            var play_url = "https://play.imsindia.com/";
        
            if (BASE_URL.includes("staging")) {
              play_url = "https://stagingplay.imsindia.com/";
            }
            window.location.replace(`${play_url}full-review/?test_id=${testId}`);
        }

    }

    const [questionStatus, setquestionStatus] = useState(0)

    const Sectionwithkey = (items, key) => {
        setSectionOrder(items)
        setquestionStatus(key)
    }


 
    const index = 0
    const choiceIndex = `${0}_${0}`
    const answerId = `exam_question_${choiceIndex ? choiceIndex : index}`


    return (
        <>

            <header className={style.header}>
                <a className={style.logo}>
                    <strong className={style.atma}>ATMA SAMPLE TEST PORTAL</strong>
                </a>
                <nav>
                    <a style={{ color: "white" }}>
                        <div id={style.countdown1}>
                            {/* <span className={style.hours}>02</span>:
                            <span className={style.minutes}>54</span>:
                            <span className={style.seconds}>50</span> */}
                            <CountdownTimer key={time_left?.time_left} initialHours={0} timeLeft={time_left?.time_left}
                                // openModal={() => openModalConclude({
                                //     title: "Time Expired",
                                //     description: "Time has expired for this section, click OK to continue."
                                // })} 
                                text={""} />
                        </div>
                    </a>
                </nav>
            </header>

            <div className={style.exam_container}>
                <div className={style.sidebar}>
                    <div className={style.user_details}>
                        <div className={style.user_block}>
                            <div className={style.user_image}>
                                <img
                                    src="https://mocktest.atmaaims.com/assets/images/candimg.jpg"
                                    alt="user-image"
                                    width="100%"
                                />
                            </div>
                        </div>

                        <div className={style.interface} style={{ color: "white" }}>
                            <div className={style.user_operation}>
                                <h2>
                                    <center style={{ fontWeight: "bold" }}>JUMP TO QUESTION</center>
                                </h2>

                                <div className={style.menu}>
                                    <div className={style.menu1}>
                                        <span className={style.filled}></span>
                                        <span className={style.attempt}>Attempted</span>
                                    </div>
                                    <div className={style.menu2}>
                                        <span className={style.unfilled}></span>
                                        <span className={style.unattempt}>Unattempted</span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.grids}>
                                <table className={style.tbl_question}>
                                    <tbody>
                                        <tr>
                                            {status?.sectional_status?.length > 0 && status?.sectional_status[questionStatus]?.map((items, i) => (
                                                <td className={style.ques_data} style={{ background: items == 0 ? "lightblue" : "" }} title="Click Here">
                                                    <a className={style.no_of_ques} onClick={() => setquestionIndex(i)} >{i + 1}</a>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={style.master_div}>
                    <div className={style.heading}>
                        <h2 className={style.sample}>Sample Test :: Question Set 1</h2>

                    </div>

                    <div className={style.sections}>
                        <b className={style.module}>
                            <u>Sections:</u>
                        </b>
                        {selector?.group_details?.length >= 0 && selector?.group_details[0]?.section_detail?.map((items, i) => (
                            <a className={style.bt} onClick={() => Sectionwithkey(items, i)}>
                                {items.name}
                            </a>
                        ))}

                    </div>

                    <div className={style.dynamic}>
                        <table className={style.dynamic_table}>
                            <tbody>
                                <tr>
                                    <td className={style.curr_ques}>
                                        <span className={style.label}>Q.{questionIndex + 1}</span>
                                    </td>

                                    <td className={style.dynamic_label}>

                                        {/* <p className={style.heading_ques}>
                                                Read the passage given below and answer the question that
                                                follows.
                                            </p>
                                            <p className={style.main_ques}>
                                                In a cricket team of eleven members, six members are already
                                                selected and five are to be selected such that there are
                                                three batsmen and two bowlers. Arvind, Baljeet, Chetan,
                                                Dilip, Elesh are batsmen and Praveen, Karan, Rajesh, Sandeep
                                                are bowlers.
                                            </p>

                                            <ol className={style.main_ques}>
                                                <li>Arvind will not go if Chetan goes.</li>
                                                <li>Praveen will go only if Karan goes.</li>
                                                <li>Karan will not go if Rajesh goes.</li>
                                                <li>Baljeet will go only if Elesh goes.</li>
                                                <li>Dilip will not go if Sandeep goes.</li>
                                                <li>Elesh will not go with Praveen.</li>
                                                <li>Baljeet will not go with Rajesh.</li>
                                            </ol>

                                            <p className={style.question_to_ask}>
                                                <b>
                                                    Question - If Baljeet is selected, then which other
                                                    players may be selected?
                                                </b>
                                            </p>

                                            <div className={style.choices}>
                                                <p className={style.choice}>I. Arvind, </p>
                                                <p>II. Dilip, Chetan,</p>
                                                <p>III. Elesh</p>
                                            </div> */}

                                        <DecodedHTMLComponent data={question?.question?.question} />
                                        <div id={`exam_question_${question?.id}`} className={style.exam_answer} key={question?.id}>
                                            <DecodedHTMLComponent data={question?.question?.answer_type} key={question?.id} />
                                        </div>


                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {/* <div className={style.options}>
                                <table>
                                    <tbody >
                                        <tr>
                                            <td className={style.options_values}>
                                                <input
                                                    id="option1"
                                                    type="radio"
                                                    name="RblOption1"
                                                    value="<p>I only</p>"
                                                />
                                                <label htmlFor="option1">
                                                    <p className={style.options_tags}>I only</p>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className={style.options_values}>
                                                <input
                                                    id="option1"
                                                    type="radio"
                                                    name="RblOption1"
                                                    value="<p>I only</p>"
                                                />
                                                <label htmlFor="option1">
                                                    <p className={style.options_tags}> I only</p>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className={style.options_values}>
                                                <input
                                                    id="option1"
                                                    type="radio"
                                                    name="RblOption1"
                                                    value="<p>I only</p>"
                                                />
                                                <label htmlFor="option1">
                                                    <p className={style.options_tags}>I only</p>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className={style.options_values}>
                                                <input
                                                    id="option1"
                                                    type="radio"
                                                    name="RblOption1"
                                                    value="<p>I only</p>"
                                                />
                                                <label htmlFor="option1">
                                                    <p className={style.options_tags}>I only</p>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                    </div>

                    <footer className={style.footer}>
                        <button className={` ${style.footer_buttons} ${style.next}`} onClick={() => SaveNext()}>
                            Save & Next Questions
                        </button>
                        <button className={` ${style.footer_buttons} ${style.prev}`} onClick={() => SavePrevious()} >
                            Save & Previous Questions
                        </button>
                        <button className={` ${style.footer_buttons} ${style.submit}`} onClick={() => SubmitTest()}>
                            Submit Test
                        </button>
                    </footer>
                </div>
            </div>

            <Loader modal={modalLoader} player={1} />
            <NmatModalwithoutBackground modal={modal} closeModal={closeModal} modalData={modalData} />


        </>
    )
}



const Atma = () => {


    const [PlayerStatus, setPlayerStatus] = useState({})

    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    useEffect(() => {
        if (Object.keys(selector).length > 0) {
            setPlayerStatus(selector)
        }
    }, [selector])




    const [questionView, setQuestionView] = useState(false)

    useEffect(() => {
        if (Object.keys(PlayerStatus).length > 0) {

            if (PlayerStatus?.status == 2 || PlayerStatus?.status == 1) {
                setQuestionView(true)
            }
        }
    }, [PlayerStatus])



    return (
        <div>
            {questionView ?
                <div>
                    <QuestionView />
                </div>
                :
                <div>
                    <Instructions instruction={PlayerStatus?.instruction} setQuestionView={setQuestionView} />
                </div>
            }
        </div>
    )
}


export default Atma