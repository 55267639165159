import "./style.css"

import style from '../Modals/modalStyle.module.css';

export const NmatModalwithoutBackground = ({ closeModal, modal,modalData }) => {
    return (
        <>
            {modal ?
                <div className={style.backGround}>
                    <div className={style.modal_div}>
                        <div className={style.title}>
                            {modalData?.title}
                        </div>
                        <hr />
                        <p className={style.description}>
                           {modalData?.description}
                        </p>

                        <div className={style.content}>
                        <button className={style.triger} onClick={()=>closeModal()}>Okay</button>
                        </div>
                        
                    </div>
                </div> : ""}

        </>
    )
}