import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import CreateQuestionSaga from './saga';
import rootReducer from './rootReducer';
import GetNmatQuestions from './saga';


const sagaMiddleware = createSagaMiddleware();
const store = configureStore(
    {
        reducer:rootReducer,
        middleware: ()=>[sagaMiddleware]
    }
    )

sagaMiddleware.run(GetNmatQuestions)
export default store;