import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const CountdownTimer = React.memo(({ endExamAndNext }) => {
  const [formattedTime, setFormattedTime] = useState('');
  const [clock, setClock] = useState(true);

  const time_left = useSelector((state)=>state.GET_TIME_LEFT)
  const timeLeft= time_left.time_left

  useEffect(() => {
    let timeInterval;

    const formatTime = (value) => {
      return value.toString().padStart(2, "0");
    }

    const startExam = () => {
      const initialTime = timeLeft;
      setFormattedTime(formatTime(Math.floor(initialTime)) + ":" + formatTime(Math.round((initialTime % 1) * 60)));

      timeInterval = setInterval(() => {
        setFormattedTime(prevFormattedTime => {
          let [minutes, seconds] = prevFormattedTime.split(":").map(Number);

          if (seconds === 0 && minutes !== 0) {
            minutes -= 1;
            seconds = 59;
          } else {
            seconds = seconds > 0 ? seconds - 1 : 0;
          }

          const newFormattedTime = formatTime(minutes) + ":" + formatTime(seconds);

          if (newFormattedTime === "00:00") {
            endExamAndNext();
            clearInterval(timeInterval);
          }

          return newFormattedTime;
        });
      }, 1000);
    };

    startExam();

    return () => clearInterval(timeInterval);
  }, [timeLeft, endExamAndNext]);

// console.log("formattedTime",formattedTime.includes("NaN"))
  return (
    <div>
      <i onClick={() => setClock(!clock)} className="far fa-clock white-icon"></i> &nbsp;
      {clock && <span>Time Left: {!formattedTime.includes("NaN") ? formattedTime:  <img src={'https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif'} width={10} height={10} />}</span>}
    </div>
  );
}, (prevProps, nextProps) => {
  // Compare only the time_left prop
  return prevProps.time_left === nextProps.time_left;
});

// export default CountdownTimer;
