import React from 'react'
import "./style.css"


const renderQuestionStatusImage = (status, classname) => {
    switch (status) {
        case 1:
            return (
                <>
                    <img className={classname} src={"https://play.imsindia.com/50233226adc27e9c3f05a8eabc8388b1.svg"} />
                    <img className="selected" src="https://play.imsindia.com/fb653b7a25749de629934456a5c3c98a.svg" />
                </>
            )
                ;
        case 2:
            return <img className={classname} src={"https://play.imsindia.com/5c513ab1a5691019a4ce1b4ac16e83c3.svg"} />;
    }
};


const Review = () => {
    return (
        <div>
            <div className='play--container'>

                {/* header  */}
                <div>
                    <div className="play-header-container ">
                        <div className="left--menu ">
                            <div className="test-close ">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                    <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div className="play-header ">
                            <div className="center-menu ">QA 2.4 Pre Class Quiz: 8/10 answered
                            </div>
                            <div className="play-filter ">
                                <div className="select-wrapper ">
                                    <div>
                                        <span className="label ">All</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                            <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="hidden "><label>
                                        <input type="radio" name="radio-30.06454447987654" value="all" />
                                        <span className="design">
                                        </span>
                                        <span className="value">All</span>
                                    </label>
                                        <label>
                                            <input type="radio" name="radio-30.06454447987654" value="1" />
                                            <span className="design">
                                            </span>
                                            <span className="value">Correct</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="radio-30.06454447987654" value="2" />
                                            <span className="design">
                                            </span>
                                            <span className="value">Incorrect</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="radio-30.06454447987654" value="0" />
                                            <span className="design">
                                            </span>
                                            <span className="value">Skipped</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="select-wrapper ">
                                    <div>
                                        <span className="label ">Quantitative Ability</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                            <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="hidden ">
                                        <label>
                                            <input type="radio" name="radio-44.097362522707996" value="Quantitative Ability" />
                                            <span className="design">
                                            </span>
                                            <span className="value">Quantitative Ability</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right--menu ">
                            <img src="https://play.imsindia.com/5deef1be0c7118363b2ade21e0719246.svg" />Scorecard
                        </div>
                    </div>
                </div>

                {/* question view  */}
                <div className='question-view'>
                    <div className='only-ques'>
                        <div className='question-container' id="46">
                            <div className="q-title"><span>1</span>/20</div>

                            

                            <div className="view-ans ">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M8.5 22.0002C10.79 21.3502 13.21 21.3502 15.5 22.0002" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                    <path d="M8.29986 18.0402V16.8802C5.99986 15.4902 4.10986 12.7802 4.10986 9.90018C4.10986 4.95018 8.65986 1.07018 13.7999 2.19018C16.0599 2.69018 18.0399 4.19018 19.0699 6.26018C21.1599 10.4602 18.9599 14.9202 15.7299 16.8702V18.0302C15.7299 18.3202 15.8399 18.9902 14.7699 18.9902H9.25986C8.15986 19.0002 8.29986 18.5702 8.29986 18.0402Z" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                </svg>
                                View Answer
                            </div>

                            <div className="solution">
                                <div className="sol-top">
                                    <div className="sol-head sol-sel">Statistics
                                    </div>
                                    <div className="sol-head ">Video Solution
                                    </div>
                                    <div className="sol-head ">Text Solution
                                    </div>
                                </div>
                                <div className="sol-body">
                                    <div>
                                        <div >
                                            <div className="stats-container">
                                                <div className="stats-lane first">
                                                    <div className="stats-elem">
                                                        <div className="stat-elem-data">
                                                            <div className="stat-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M3.70576 19.2383C2.69242 17.7673 2.10743 16.0546 2.01344 14.2835C1.91944 12.5124 2.31998 10.7496 3.17215 9.18401C4.02433 7.6184 5.29604 6.3089 6.8511 5.39575C8.40617 4.4826 10.186 4.0002 12 4.0002C13.814 4.0002 15.5938 4.4826 17.1489 5.39575C18.704 6.3089 19.9757 7.6184 20.8278 9.18401C21.68 10.7496 22.0806 12.5124 21.9866 14.2835C21.8926 16.0546 21.3076 17.7673 20.2942 19.2383" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    <path d="M12 16.0002C13.1046 16.0002 14 15.1048 14 14.0002C14 12.8956 13.1046 12.0002 12 12.0002C10.8954 12.0002 10 12.8956 10 14.0002C10 15.1048 10.8954 16.0002 12 16.0002Z" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    <path d="M13.4287 12.5716L16.001 9.99922" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                </svg>
                                                            </div>

                                                            <div className="stat-elem-value correct">Attempted</div>

                                                        </div>
                                                        <div className="stat-elem-text">
                                                            Attempt
                                                        </div>
                                                    </div>
                                                    <div className="stats-elem">
                                                        <div className="stat-elem-data">
                                                            <div className="stat-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M3.70576 19.2383C2.69242 17.7673 2.10743 16.0546 2.01344 14.2835C1.91944 12.5124 2.31998 10.7496 3.17215 9.18401C4.02433 7.6184 5.29604 6.3089 6.8511 5.39575C8.40617 4.4826 10.186 4.0002 12 4.0002C13.814 4.0002 15.5938 4.4826 17.1489 5.39575C18.704 6.3089 19.9757 7.6184 20.8278 9.18401C21.68 10.7496 22.0806 12.5124 21.9866 14.2835C21.8926 16.0546 21.3076 17.7673 20.2942 19.2383" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    <path d="M12 16.0002C13.1046 16.0002 14 15.1048 14 14.0002C14 12.8956 13.1046 12.0002 12 12.0002C10.8954 12.0002 10 12.8956 10 14.0002C10 15.1048 10.8954 16.0002 12 16.0002Z" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    <path d="M13.4287 12.5716L16.001 9.99922" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                </svg>
                                                            </div>

                                                            <div className="stat-elem-value correct">Correct</div>

                                                        </div>
                                                        <div className="stat-elem-text">
                                                            Your Result
                                                        </div>
                                                    </div>
                                                    <div className="stats-elem">
                                                        <div className="stat-elem-data">
                                                            <div className="stat-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    <path d="M12 8V13" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    <path d="M9 2H15" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                </svg>
                                                            </div>
                                                            <div className="stat-elem-value">00:03</div>
                                                        </div>
                                                        <div className="stat-elem-text">
                                                            Your pace
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>

                {/* footer  */}
                <div className="footer-container">
                    <div className="question-list">
                        <div className="pagination-btn page-disabled">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14.9998 19.9181L8.47984 13.3981C7.70984 12.6281 7.70984 11.3681 8.47984 10.5981L14.9998 4.07812" stroke="#C7CCD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                            </svg>
                        </div>

                        <div className="go-to-ques answered" style={{ display: "inherit" }}>
                            {renderQuestionStatusImage(1, "review")}
                            1

                        </div>
                        <div className="go-to-ques " style={{ display: "inherit" }}>
                            {renderQuestionStatusImage(2, "scoreCard-review")}2
                        </div>
                        <div className="go-to-ques " style={{ display: "inherit" }}>3
                        </div>
                        <div className="go-to-ques " style={{ display: "inherit" }}>
                            {renderQuestionStatusImage(2, "scoreCard-review")}4
                        </div>
                        <div className="go-to-ques answered" style={{ display: "inherit" }}>
                            <img className="review" src="https://play.imsindia.com/50233226adc27e9c3f05a8eabc8388b1.svg" />5
                        </div>
                        <div className="go-to-ques answered" style={{ display: "inherit" }}>
                            <img className="review" src="https://play.imsindia.com/50233226adc27e9c3f05a8eabc8388b1.svg" />6
                        </div>
                        <div className="go-to-ques " style={{ display: "inherit" }}>7
                        </div>
                        <div className="go-to-ques " style={{ display: "inherit" }}>
                            {renderQuestionStatusImage(2, "scoreCard-review")}8
                        </div>
                        <div className="go-to-ques answered" style={{ display: "inherit" }}>
                            <img className="review" src="https://play.imsindia.com/50233226adc27e9c3f05a8eabc8388b1.svg" />9
                        </div>
                        <div className="go-to-ques " style={{ display: "inherit" }}>
                            {renderQuestionStatusImage(2, "scoreCard-review")}10
                        </div>
                        <div className="pagination-btn page-disabled">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8.91016 19.9181L15.4302 13.3981C16.2002 12.6281 16.2002 11.3681 15.4302 10.5981L8.91016 4.07812" stroke="#C7CCD1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                            </svg>
                        </div>
                    </div>
                    <div className="btn-grp">
                        <div className="next-btn next-enabled">Next
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Review