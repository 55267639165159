
import { takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import API, { GetInfoUrl, NmatTimeLeft, TimeLeft, getPingAPIUrl, getSelectedQuestionDetailsUrl } from "../action/apiPath"
import { GetInfoURL, GetTimeleft, GetTimeleftOthers } from './action';
import { GET_INFO, GET_INFO_DATA, GET_SELECTED_QUESTION_DETAILS_REQUESTED, GET_SELECTED_QUESTION_DETAILS_SUCCEEDED, GET_TIME_LEFT, GET_TIME_LEFT_OTHER, GET_TIME_LEFT_SUCCESSED, PING } from './constant';


function* GetInfo({ testId }) {
  //  console.log("data",testId)
  if (testId !== null) {
    const url = GetInfoUrl(testId);
    const config = {
      headers: {
        'Authorization': `jwt ${Cookies.get('accessToken')}` // Include the auth token in the header
      }
    };
    let response = yield call(axios.get, url, config)
    if (response.status === 200) {
      yield put({ type: GET_INFO_DATA, response })

      const current_section = response?.data?.group_details?.find(
        (group) => group.status === 0 || group.status === 1
      )

      if (current_section === undefined) {
      } else {
        // console.log(current_section)
        if (response?.data?.player == 4) {
          yield put(GetTimeleft(Number(response?.data?.id), current_section.id))
        }else{
          // yield put(GetTimeleftOthers(Number(response?.data?.id), current_section.id))
        }

      }




    } else {
      yield put({ type: GET_INFO_DATA, response })
    }
  } else {
    alert("TEST IS NOT ENTERED")
  }

}


function* getSelectedQuestionDetails(action) {
  try {
    const questionId = action.qid;
    const url = getSelectedQuestionDetailsUrl(questionId);
    const config = {
      headers: {
        'Authorization': `jwt eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNzA1NDg4NTg2LCJlbWFpbCI6IiIsIm9yaWdfaWF0IjoxNzA1NDc0MTg2LCJhdWQiOiJQeXRob25BcGkiLCJpc3MiOiJDZXJlYnJ5In0.cKGzqiyZqZTc5NNY-iaBUkgEHfrN0VPXBWe1yV43RI4` // Include the auth token in the header
      }
    };
    let response = yield call(axios.get, url, config)

    if (response.status < 400) {
      // resp.data.question = b64DecodeUnicode(resp.data.question);
      // if (resp.data.solution)
      //   resp.data.solution = b64DecodeUnicode(resp.data.solution);
      yield put({
        type: GET_SELECTED_QUESTION_DETAILS_SUCCEEDED,
        data: response,
      });
    }
    // else {
    //   yield put({
    //     type: GET_SELECTED_QUESTION_DETAILS_FAILED,
    //     resp,
    //   });
    // }
  } catch (e) {
    // yield put({
    //   type: GET_SELECTED_QUESTION_DETAILS_FAILED,
    //   message: e.message,
    // });
  }
}

function* getTimeleft(action) {
  try {
    const testId = action.testId;
    const groupID = action.groupID;
    const url = NmatTimeLeft(testId, groupID);
   
    const config = {
      headers: {
        'Authorization': `jwt ${Cookies.get('accessToken')}` // Include the auth token in the header
      }
    };
    let response = yield call(axios.get, url, config)
    // console.log(url,response)
    if (response.status < 400) {
      // resp.data.question = b64DecodeUnicode(resp.data.question);
      // if (resp.data.solution)
      //   resp.data.solution = b64DecodeUnicode(resp.data.solution);
      yield put({
        type: GET_TIME_LEFT_SUCCESSED,
        data: response,
      });
    }
    // else {
    //   yield put({
    //     type: GET_SELECTED_QUESTION_DETAILS_FAILED,
    //     resp,
    //   });
    // }
  } catch (e) {
    // yield put({
    //   type: GET_SELECTED_QUESTION_DETAILS_FAILED,
    //   message: e.message,
    // });
  }
}

function* getTimeleftOther(action) {
  try {
    const testId = action.testId;
    const groupID = action.groupID;
    const url = TimeLeft(testId, groupID);
    const config = {
      headers: {
        'Authorization': `jwt ${Cookies.get('accessToken')}` // Include the auth token in the header
      }
    };
    let response = yield call(axios.get, url, config)

    if (response.status < 400) {
      // resp.data.question = b64DecodeUnicode(resp.data.question);
      // if (resp.data.solution)
      //   resp.data.solution = b64DecodeUnicode(resp.data.solution);
      yield put({
        type: GET_TIME_LEFT_SUCCESSED,
        data: response,
      });
    }
    // else {
    //   yield put({
    //     type: GET_SELECTED_QUESTION_DETAILS_FAILED,
    //     resp,
    //   });
    // }
  } catch (e) {
    // yield put({
    //   type: GET_SELECTED_QUESTION_DETAILS_FAILED,
    //   message: e.message,
    // });
  }
}


function* getPingAPI(action) {
  try {
    const testId = action.testId;
    const url = getPingAPIUrl(testId);
    const config = {
      headers: {
        'Authorization': `jwt ${Cookies.get('accessToken')}` // Include the auth token in the header
      }
    };

    const resp = yield call(axios.get, url,config, testId);
  } catch (err) {
    console.log("error", err)
  }
}





function* GetNmatQuestions() {
  yield takeEvery(GET_INFO, GetInfo)
  yield takeEvery(GET_SELECTED_QUESTION_DETAILS_REQUESTED, getSelectedQuestionDetails)
  yield takeEvery(GET_TIME_LEFT, getTimeleft)
  yield takeEvery(GET_TIME_LEFT_OTHER, getTimeleftOther)
  yield takeEvery(PING, getPingAPI)

}

export default GetNmatQuestions;
