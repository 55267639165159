
const BASE_URL_NMAT = process.env.REACT_APP_API_KEY_NMAT
const BASE_URL = process.env.REACT_APP_API_KEY

// _____________ AUTHENTICATION _____________
export const GET_AUTHENTICATION= `${BASE_URL}me/`  //GET
export const POST_REFRESH_AUTH= `${BASE_URL}refresh/`  //POST

//_____________ QUESTION CREATION _____________
// export const POST_INITIALIZING_QUESTION_CREATION= `${BASE_URL}initialize-question-creation/`  //POST
// export const PATCH_SELECT_QUESTION= `${BASE_URL}question-draft/`  //GET
// export const GET_FREE_TAG= `${BASE_URL}free-tags/`  //GET
// export const GET_QUESTIOB_TYPE= `${BASE_URL}question-types/`  //GET
// export const GET_AUTHOR= `${BASE_URL}question-authors/`  //GET


// // _____________ QUESTION LISTING _____________
// export const GET_QUESTION_BANK= `${BASE_URL}question-bank/`  //GET
// export const GET_QUESTION= `${BASE_URL}question/`  //GET
// export const GET_QUESTION_DRAFT= `${BASE_URL}question-draft/`  //GET


// // _______________ MASTERS _______________
// export const GET_CATEGORY= `${BASE_URL}categories/`  //GET
// export const CREATE_CATEGORY= `${BASE_URL}create-category/`  //POST
// export const EDIT_CATEGORY= `${BASE_URL}categories/`  //PATCH

// export const GET_IDENTIFIER= `${BASE_URL}identifiers/`  //GET
// export const CREATE_IDENTIFIER= `${BASE_URL}create-identifier/`  //POST
// export const EDIT_IDENTIFIER= `${BASE_URL}identifiers/`  //PATCH

// export const GET_LOD= `${BASE_URL}lod-list/`  //GET
// export const CREATE_LOD= `${BASE_URL}create-lod/`  //POST
// export const LOD= `${BASE_URL}lod/`  //PATCH

// export const GET_QUESTIONBANK= `${BASE_URL}question-banks/`  //GET
// export const CREATE_QUESTIONBANK= `${BASE_URL}create-question_bank/`  //POST
// export const EDIT_QUESTIONBANK= `${BASE_URL}question-banks/`  //PATCH

// export const GET_SUBJECT_TAG= `${BASE_URL}subject-tags/`  //GET
// export const CREATE_SUBJECT_TAG= `${BASE_URL}create-subject-tags/`  //POST
// export const EDIT_SUBJECT_TAG= `${BASE_URL}subject-tags/`  //PATCH



// // ________ USERS ___________
// export const GET_USERS= `${BASE_URL}users/`  //GET
// export const GET_ROLES= `${BASE_URL}roles/`  //GET
// export const CREATE_USERS= `${BASE_URL}create-user/`  //POST


// // ___________ organisation ___________
// export const GET_ORGANISATION= `${BASE_URL}organizations/`  //GET



// ____________________ INFO ____________________
export const GetInfoUrl = (testID) => {
    return `${BASE_URL}exam/${testID}/info/`
  }







  // Instructions 
  export const GetNmatInstructionUrl = (screen) => {
    return `${BASE_URL_NMAT}exam/${screen}/fetch_instruction/`
  }


  export const SubmitNmatInstructionUrl = (screen) => {
    return `${BASE_URL_NMAT}exam/${screen}/submit_instruction/`
  }

  // question 
  export const NmatQuestionViewUrl = (id) => {
    return `${BASE_URL_NMAT}exam_config_type/${id}/question/`
  }

  export const NmatQuestionStatusViewUrl = (testId) => {
    return `${BASE_URL_NMAT}exam/${testId}/questions-attempt-status/`
  }


  export const NmatQuestionSubmitUrl = (id) => {
    return `${BASE_URL_NMAT}exam/${id}/ims-submit-answer/`
  }

  export const NmatSectionSubmitUrl = (testId, groupId) => {
    return `${BASE_URL_NMAT}exam/${testId}/group/${groupId}/ims-submit-group/`
  }

  // time 
  export const NmatTimeLeft = (testId, groupId) => {
    return `${BASE_URL_NMAT}exam/${testId}/group/${groupId}/time-left/`
  }










// Instructions 
  export const GetInstructionUrl = (screen) => {
    return `${BASE_URL}exam/${screen}/fetch_instruction/`
  }

  // question 
  export const QuestionViewUrl = (id) => {
    return `${BASE_URL}exam_config_type/${id}/question/`
  }

  export const QuestionStatusViewUrl = (testId) => {
    return `${BASE_URL}exam/${testId}/questions-attempt-status/`
  }


  export const QuestionSubmitUrl = (id) => {
    return `${BASE_URL}exam/${id}/ims-submit-answer/`
  }

  export const SectionSubmitUrl = (testId, groupId) => {
    return `${BASE_URL}exam/${testId}/group/${groupId}/ims-submit-group/`
  }

  // time 
  export const TimeLeft = (testId, groupId) => {
    return `${BASE_URL}exam/${testId}/group/${groupId}/time-left/`
  }








  // score card 
  export const ScoreCardUrl = (testId) => {
    return `${BASE_URL}v2/users/test/${testId}/score-card/`
  }


 export const getSelectedQuestionDetailsUrl = (questionid) => {
    return `${BASE_URL}v2/users/question/${questionid}/score-card-question/`;
  };
  
  export const getPingAPIUrl = (testid) => {
    return `${BASE_URL}v2/users/exam/${testid}/ping/`;
  };