import axios from "axios";
import Cookies from "js-cookie";

export function CallWithOutAuth(method, url, body) {
  return new Promise((resolve, reject) => {
    try {
      if (method === "POST") {
        axios
          .post(url, body)
          .then((response) => {
          
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PUT") {
        axios
          .put(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PATCH") {
        axios
          .patch(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "GET") {
        axios
          .get(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      
      reject(err.response);
    }
  });
}

export function CallWithAuth(method, url, body = {}) {
  const header = {
    headers: { Authorization: `jwt ${Cookies.get("accessToken")}` },
  };


  return new Promise((resolve, reject) => {
    try {
      if (method === "POST") {
        axios
          .post(url, body, header )
          .then((response) => {
          //  console.log (response)
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PUT") {
        axios
          .put(url, body, header)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PATCH") {
        axios
          .patch(url, body, header )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "GET") {
        axios
          .get(url, header )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "DELETE") {
        axios
          .delete(url,header)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      // console.log("Catch block error in CallWithAuth method");
      // console.log(err);
      reject(err.response);
    }
  });
}


export function CallForAuth(auth,method, url, body = {}) {
  const header = {
    headers: { Authorization: auth },
  };


  return new Promise((resolve, reject) => {
    try {
      if (method === "POST") {
        axios
          .post(url, body, header )
          .then((response) => {
            // console.log(response)
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PUT") {
        axios
          .put(url, body, header)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "PATCH") {
        axios
          .patch(url, body, header )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "GET") {
        axios
          .get(url, header )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === "DELETE") {
        axios
          .delete(url,header)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      // console.log("Catch block error in CallWithAuth method");
      // console.log(err);
      reject(err.response);
    }
  });
}