import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import "./style.css"
import { CallWithAuth } from '../../action/apiActions'
import { ScoreCardUrl } from '../../action/apiPath'
import { selectedQuestionDetails } from '../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { DecodedHTMLComponent } from '../../Components/Utils/DecodeHTMLComponent'
import Review from '../Review'



const renderQuestionStatusImage = (status, classname) => {
    switch (status) {
        case 1:
            return <img className={classname} src={"https://play.imsindia.com/50233226adc27e9c3f05a8eabc8388b1.svg"} />;
        case 2:
            return <img className={classname} src={"https://play.imsindia.com/5c513ab1a5691019a4ce1b4ac16e83c3.svg"} />;
    }
};


const QuestionView = () => {
    const question = useSelector((state) => state.GET_SCORECARD_QUESTION_DETAIL);
    if (!question) return null;
    // console.log(question)
    return (
        <div
            className={"question-view-container"}>
            <div className={"title-container"}>
                {renderQuestionStatusImage(question.status, "review")}
                <span className={"question-number"}>{question.title}</span>
                <span className={"status-message"}>
                    {(question.status === 0 && "Not attempted") ||
                        (question.status === 1 && "Correct answer") ||
                        (question.status === 2 && "Wrong answer")}
                </span>
            </div>
            {/* <div
                className={"question"}
                dangerouslySetInnerHTML={{ __html: question?.question }}
            ></div> */}
            <DecodedHTMLComponent data={question?.question} />

            {/* <div
                className={"answer"}
                dangerouslySetInnerHTML={{ __html: question?.solution }}
            ></div> */}
             <DecodedHTMLComponent data={question?.solution} />
        </div>
    );
};

const ScoreCard = () => {
    const [queryParameters] = useSearchParams()
    const testId = queryParameters.get("test_id")
    // console.log(testId)

    const [scoreData, setScoreData] = useState({
        "test_name": "QA 2.4 Pre Class Quiz",
        "pass_message": "Great! You have completed the test",
        "score_obtained": 8.0,
        "total_score": 30,
        "questions_correct": 4,
        "total_questions": 0,
        "time_taken": "00:49",
        "time_message": "Suggested Time 10:00",
        "questions": [
            {
                "id": 14646,
                "status": 1
            },
            {
                "id": 14647,
                "status": 2
            },
            {
                "id": 14648,
                "status": 0
            },
            {
                "id": 14649,
                "status": 2
            },
            {
                "id": 14650,
                "status": 1
            },
            {
                "id": 14651,
                "status": 1
            },
            {
                "id": 14652,
                "status": 0
            },
            {
                "id": 14653,
                "status": 2
            },
            {
                "id": 14654,
                "status": 1
            },
            {
                "id": 14655,
                "status": 2
            }
        ]
    })


    // _________________ API call of scorecard _________________

    // const GetScoreCard=async()=>{
    //     const url = ScoreCardUrl(queryParameters.get("test_id"))
    //     const data= await CallWithAuth("GET", url)
    //     console.log(data)
    //     if(data?.res?.status==200){

    //     }
    // }

    //     useEffect(()=>{
    // GetScoreCard()
    //     },[])

    // _________________ API call of scorecard _________________




    const handleQuestionListsDivisions = (questionsArray) => {
        const range = 10;
        const array_length = questionsArray.length / range;
        const new_array_length = Math.ceil(array_length);
        const arr = [];
        let offset = 0;
        for (let index = 0; index < new_array_length; index++) {
            arr.push(questionsArray.slice(offset, offset + range));
            offset += range;
        }
        return arr;
    };


    const dispatch = useDispatch()
    //   const selector = useSelector((state)=>state.GET_SCORECARD_QUESTION_DETAIL)


    return (
        <div>
            <Review/>
            
            {/* <div className='scorecard_div'>
                <div className="scorecard_div_header" >
                    <div className="left-menu__2c22y">
                    </div>
                    <div className="center-menu">
                        <div className="test-name">{scoreData?.test_name}</div></div>
                    <div className="right-menu">
                        <div className="test-close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7.75781 7.75781L16.2431 16.2431" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.75691 16.2431L16.2422 7.75781" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </div>
                    </div>
                </div>

                <div className="score-card-web-container">
                    <div className="score-card-body  ">
                        <div className="score-card-title">
                            <div className="head-title">{scoreData?.pass_message}
                            </div>
                            <div className="head-subtitle">Please go through the solutions to master incorrect / skipped questions
                            </div>
                        </div>
                        <div className="info-cards">
                            <div className="card">
                                <div className="card-data">{scoreData?.score_obtained}
                                </div>
                                <div className="card-desc">
                                    <img src="https://play.imsindia.com//a8f2eec6511eeb79910d1b874e8b5ef2.svg" /> &nbsp; Score out of {scoreData?.total_score}
                                </div>
                            </div>
                            <div className="card" style={{ margin: "0px 18px" }}>
                                <div className="card-data "> {scoreData?.questions_correct}
                                </div>
                                <div className="card-desc ">
                                    <img src="https://play.imsindia.com//3a96a1509747ccc319fdb7eaf40399b2.svg" /> &nbsp; Target Correct {scoreData?.total_questions}
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-data ">{scoreData?.time_taken}
                                </div>
                                <div className="card-desc ">
                                    <img src="https://play.imsindia.com//eba7642a866e1aff296cae0f6e4a555b.svg" />&nbsp; {scoreData?.time_message}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="performance-review-container">
                        <div className="questions-list-container">
                            <div className="title">Review your performance
                            </div>

                            {handleQuestionListsDivisions(scoreData?.questions)?.map((items, i) => (
                                <div className="question-list " key={i}>
                                    {items?.map((question, key) => (
                                        <div className="go-to-ques" key={i + "-" + key} onClick={() => dispatch(selectedQuestionDetails(question.id))}>
                                            {renderQuestionStatusImage(question.status, "scoreCard-review")}
                                             {(i * 10) + key + 1}
                                        </div>
                                    ))}

                                </div>
                            ))}
                        </div>
                        <div className="detailed-review-btn">Detailed review
                        </div>
                    </div>
                    <QuestionView />
                </div>



            </div> */}
        </div>
    )
}

export default ScoreCard