import React, { useEffect, useState } from 'react'
import "./style.css"
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { DecodedHTMLComponent } from '../../Components/Utils/DecodeHTMLComponent';
import { CallWithAuth } from '../../action/apiActions';
import { QuestionStatusViewUrl, QuestionSubmitUrl, QuestionViewUrl, SectionSubmitUrl, TimeLeft } from '../../action/apiPath';
import { Loader } from '../../Components/Modals/Loader';
import { CountdownTimer } from '../../Components/Utils/NmatTimerCoundown';
import { GetInfo, GetTimeleftOthers, Ping } from '../../redux/action';

import style from '../Nta/ntaStyle.module.css'
import Cookies from 'js-cookie';
import useMobileCheck from './MobileCheck';
import { NotCompatible } from '../../Components/NotCompatible';

export const QuestionView = ({ setQuestionView }) => {



    const [queryParameters] = useSearchParams()

    const [time_left, setTimeLeft] = useState({})
    const [status, setStatus] = useState({})

    const [question, setQuestion] = useState({})

    const [questionIndex, setquestionIndex] = useState(0)
    const [sectionOrder, setSectionOrder] = useState({})
    const [current, setCurrent] = useState(null)

    const [modals, setModals] = useState(false)

    const [isPageHidden, setIsPageHidden] = useState(false);

    useEffect(() => {
      const handleVisibilityChange = () => {
        setIsPageHidden(document.hidden);
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

    
    useEffect(() => {
        if (isPageHidden == true) {
            // alert("ssss")
              setModals(true)
        } else {
            // alert("pppp")
              setModals(false)
              if(current != null){
                dispatch(GetTimeleftOthers(queryParameters.get("test_id"), current?.id))
              }
        }
    }, [isPageHidden])


    // const Time_left = async (current_section) => {
    //     dispatch(GetTimeleftOthers(queryParameters.get("test_id"), current_section.id))
    //     // console.log("current_section",current_section)
    //     // const url = TimeLeft(queryParameters.get("test_id"), current_section.id)
    //     // const data = await CallWithAuth("GET", url)
    //     // // console.log(data)
    //     // if (data?.res?.status == 200) {
    //     //     setTimeLeft(data?.res?.data)
    //     // }

    // }

    const GetQuestion = async (id) => {
        openModalLoader()
        setTimeout(async () => {
            const url = QuestionViewUrl(id)
            // console.log(url)
            const data = await CallWithAuth("GET", url)
            // console.log("dataper", data)
            // console.log("checkData", data.res.data.user_response, `exam_question_${data.res.data.id}`)
            if (data.res.status == 200) {
                setQuestion(data.res.data)
                GetQuestionStatus(queryParameters.get("test_id"))
                if (current) {
                    setTimeout(() => {
                        dispatch(GetTimeleftOthers(queryParameters.get("test_id"), current.id))
                    }, 1000)
                }

                closeModalLoader()

                // if (data.res.data.user_response != null) {

                // }

                if (data.res.data.user_response != null && data.res.data.id != null) {
                    setTimeout(() => {
                        window.parse_answer.insertAnswers(data.res.data.user_response, `exam_question_${data.res.data.id}`)
                    }, 1000);
                }

            }
        }, 1000);



    }

    const GetQuestionStatus = async (testId) => {
        const url = QuestionStatusViewUrl(testId)
        const data = await CallWithAuth("GET", url)
        if (data.res.status == 200) {
            // console.log("check00", data?.res?.data)
            // const current_Question = data?.res?.data?.sectional_status[0]?.findIndex(
            //     (item) => item === 1 || item === 2
            // )
            // console.log(current_Question)
            // if(current_Question >= 0){
            // setquestionIndex(current_Question)
            setStatus(data?.res?.data)
            // }

            // setQuestion(data.res.data.question)
        }
    }


    // useEffect(() => {
    //     console.log("sectionOrder", sectionOrder)
    //     if (Object.keys(sectionOrder).length > 0) {
    //         if (questionIndex >= 0) {
    //             GetQuestion(sectionOrder.section_detail[0]?.ct_ids[questionIndex])
    //             GetQuestionStatus(queryParameters.get("test_id"))
    //         } else {
    //             GetQuestion(sectionOrder.section_detail[0]?.ct_ids[0])
    //             GetQuestionStatus(queryParameters.get("test_id"))
    //         }

    //     }

    // }, [sectionOrder, questionIndex])

    const [questionGet, setQuestionGet] = useState(false)

    useEffect(() => {
        // console.log(sectionOrder)
        if (Object.keys(sectionOrder).length > 0) {
            if (questionIndex >= 0) {
                GetQuestion(sectionOrder?.ct_ids[questionIndex])
            }
        }
    }, [questionIndex, questionGet])

    useEffect(() => {

        // console.log("sectionOrder", sectionOrder)

        if (questionIndex == 0) {
            setQuestionGet(!questionGet)
        } else {

            if (selector.last_visited_question) {
                const activeSection = selector.group_details.find(item => item.status === 1)
                const index = activeSection.section_detail[0].ct_ids.findIndex(item => item === selector.last_visited_question)
                // console.log("selector--", index)
                setquestionIndex(index)
            }else{
                setquestionIndex(0)
            }
        }


    }, [sectionOrder])

    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    useEffect(() => {
        if (selector?.group_details.length > 0) {
            const current_section = selector?.group_details?.find(
                (group) => group.status === 0 || group.status === 1
            )
            // console.log("checkk-->", current_section, selector)
            if (current_section === undefined) {
                setQuestionView(2)
                // alert("Exam is completed please, reset the ID")
            } else {
                setCurrent(current_section)
                setSectionOrder(current_section?.section_detail[0])
                const index = selector?.group_details.findIndex(item => item?.id === current_section?.id);
                Sectionwithkey(index)
                GetQuestion(current_section?.section_detail[0]?.ct_ids[0])

            }

        }


        // GetQuestionStatus(queryParameters.get("test_id"))
        // }

    }, [selector])



    // MODAL
    const [modalLoader, setModalLoader] = useState(false)


    const openModalLoader = (e) => {
        setModalLoader(true)
    }

    const closeModalLoader = () => {
        setModalLoader(false)
    }


    // console.log("selector", selector, sectionOrder)

    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState({})

    const openModal = (e) => {
        setModal(true)
        setModalData(e)
    }

    const closeModal = () => {
        setModal(false)
    }

    const SaveNext = async (review) => {
        const answer = window.parse_answer.extractAnswers();

        if (answer[0][0] == null) {
            // openModalLoader()
            alert("Please Input any options")
            // if ((questionIndex + 1) >= sectionOrder?.ct_ids.length) {
            //     setquestionIndex(0)
            // } else {
            //     setquestionIndex(questionIndex + 1)
            // }

            // closeModalLoader()
        } else {
            openModalLoader()
            if (sectionOrder?.ct_ids[questionIndex]) {
                const url = QuestionSubmitUrl(sectionOrder?.ct_ids[questionIndex])
                const body = {
                    "object_id": sectionOrder?.ct_ids[questionIndex],
                    "user_response": JSON.stringify(answer).replace(/"/g, ""),
                    "user_response_ui": answer,
                    "review": review,
                }

                const data = await CallWithAuth("POST", url, body)
                if (data.res.status == 201) {
                    if ((questionIndex + 1) >= sectionOrder?.ct_ids.length) {
                        setquestionIndex(0)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    } else {
                        setquestionIndex(questionIndex + 1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    }
                }
            }
        }

    }

    const MarkForReview = async (review) => {
        const answer = window.parse_answer.extractAnswers();

        openModalLoader()
        if (sectionOrder?.ct_ids[questionIndex]) {
            const url = QuestionSubmitUrl(sectionOrder?.ct_ids[questionIndex])
            const body = {
                "object_id": sectionOrder?.ct_ids[questionIndex],
                "user_response": "[[null]]",
                "user_response_ui": answer,
                "review": review,
            }
            const data = await CallWithAuth("POST", url, body)
            if (data.res.status == 201) {
                if ((questionIndex + 1) >= sectionOrder?.ct_ids.length) {
                    setquestionIndex(0)
                    closeModalLoader()
                    window.scrollTo(0, 0);
                } else {
                    setquestionIndex(questionIndex + 1)
                    closeModalLoader()
                    window.scrollTo(0, 0);
                }
            }
        }


    }


    const [clear, setClear] = useState(false)
    const ClearResponse = async () => {
        const answer = window.parse_answer.extractAnswers();
        if (answer[0][0] == null) {
            alert("please input the fields")
        } else {
            const url = QuestionSubmitUrl(sectionOrder?.ct_ids[questionIndex])
            const body = {
                "object_id": sectionOrder?.ct_ids[questionIndex],
                "clear": true,
            }
            const data = await CallWithAuth("POST", url, body)
            if (data.res.status == 201) {
                setquestionIndex(questionIndex)
                GetQuestion(sectionOrder?.ct_ids[questionIndex])
                setClear(!clear)
            }
        }
    }

    const SavePrevious = async () => {
        const answer = window.parse_answer.extractAnswers();

        if (answer[0][0] == null) {
            openModalLoader()
            // openModal({
            //     title: "Answer Required",
            //     description: "You cannot continue with this question unanswered."
            // })
            if ((questionIndex == 0)) {
                setquestionIndex(sectionOrder?.ct_ids.length - 1)
            } else {
                setquestionIndex(questionIndex - 1)
            }

            closeModalLoader()
        } else {
            openModalLoader()
            if (sectionOrder?.ct_ids[questionIndex]) {
                const url = QuestionSubmitUrl(sectionOrder?.ct_ids[questionIndex])
                const body = {
                    "object_id": sectionOrder?.ct_ids[questionIndex],
                    "user_response": JSON.stringify(answer).replace(/"/g, ""),
                    "user_response_ui": answer
                }
                const data = await CallWithAuth("POST", url, body)
                if (data.res.status == 201) {
                    if ((questionIndex == 0)) {
                        setquestionIndex(sectionOrder?.ct_ids.length - 1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    } else {
                        setquestionIndex(questionIndex - 1)
                        closeModalLoader()
                        window.scrollTo(0, 0);
                    }
                }
            }
        }

    }

    const dispatch = useDispatch()

    const Submit = async () => {
        setExamSummary(false)
    }

    const SubmitTest = async () => {
        const testId = queryParameters.get("test_id")
        const groupId = selector?.group_details[0]?.id
        const url = SectionSubmitUrl(testId, groupId)
        const body = ""
        const data = await CallWithAuth("POST", url, body)
        // console.log(data)
        if (data.res.status == 201) {
            dispatch(GetInfo(testId))
            setExamSummary(true)
        }


    }

    const Next = () => {
        if ((questionIndex + 1) >= sectionOrder?.ct_ids.length) {
            setquestionIndex(0)
        } else {
            setquestionIndex(questionIndex + 1)
        }
    }

    const Back = () => {
        if ((questionIndex == 0)) {
            setquestionIndex(sectionOrder?.ct_ids.length - 1)
        } else {
            setquestionIndex(questionIndex - 1)
        }
    }

    const [questionStatus, setquestionStatus] = useState(0)

    const Sectionwithkey = (key) => {
        setquestionStatus(key)
    }


    const index = 0
    const choiceIndex = `${0}_${0}`
    const answerId = `exam_question_${choiceIndex ? choiceIndex : index}`

    // __________________ Screen size changer  __________________

    const [isClicked, setClicked] = useState(false);

    /******______ Function to make full screen width______*****/
    const handleArrowButtonClick = () => {
        const newIsClicked = !isClicked;
        setClicked(newIsClicked);
        // console.log(`Arrow button clicked. isClicked: ${newIsClicked}`);
    };



    const [examSummary, setExamSummary] = useState(true)

    const NotSubmit = () => {
        setExamSummary(true)
    }


    useEffect(() => {
        if (selector.last_visited_question) {
            const activeSection = selector.group_details.find(item => item.status === 1)
            const index = activeSection.section_detail[0].ct_ids.findIndex(item => item === selector.last_visited_question)
            // console.log("selector--", index)
            setquestionIndex(index)
        }
    }, [selector])

    return (
        <>
            {examSummary ?
                <div className={`${style.exam_page} ${isClicked ? `${style.clicked}` : ""}`}>
                    <div className={style.user_header}>
                        <nav>
                            <Link to="#" className={style.user_link}>
                                <i className={` ${style.fa} ${style.fa_user}`}></i> Home
                            </Link>
                        </nav>
                    </div>

                    <header className={style.nta_header}>
                        <nav>
                            <div className={style.nta_images}>
                                <Link to="#">
                                    <img
                                        src="https://www.nta.ac.in/img/150g.png"
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_1}`}
                                    />
                                    <img
                                        src="https://www.nta.ac.in/img/NTA_logo.png"
                                        style={{ width: "320px" }}
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_2}`}
                                    />
                                    <img
                                        src="https://www.nta.ac.in/img/moe1.png"
                                        style={{ width: "220px" }}
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_3}`}
                                    />
                                    <img
                                        src="https://www.nta.ac.in/img/akam.png"
                                        style={{ height: "60px" }}
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_4}`}
                                    />
                                </Link>
                            </div>
                        </nav>
                    </header>

                    <header className={style.genral_instructions_header}>
                        <div className={style.user_profile_container}>
                            <div className={style.table_responsive}>
                                <table className={style.user_info_table}>
                                    <tbody>
                                        <tr>
                                            <td className={style.user_icon_cell}>
                                                <i className={` ${style.fa} ${style.fa_user} ${style.user_icon} ${style.student_img}`}></i>
                                            </td>
                                            <td className={style.user_details_cell}>
                                                <table className={style.user_details_table}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={style.detail_label}>Candidate Name</td>
                                                            <td className={style.detail_value}>
                                                                {" "}
                                                                :{" "}
                                                                <span className={style.highlight_text}>{Cookies.get('firstName')} {Cookies.get('lastName')} </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Exam Name</td>
                                                            <td className={style.detail_label}>
                                                                {" "}
                                                                : <span className={style.highlight_text}>{selector.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Subject Name</td>
                                                            <td className={style.detail_value}>
                                                                {" "}
                                                                : <span className={style.highlight_text}>{sectionOrder.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Remaining Time </td>
                                                            <td className={style.detail_value}>
                                                                <CountdownTimer endExamAndNext={SubmitTest}
                                                                    // openModal={() => openModalConclude({
                                                                    //     title: "Time Expired",
                                                                    //     description: "Time has expired for this section, click OK to continue."
                                                                    // })} 
                                                                    text={""} />


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </header>

                    <div className={style.exam_container}>
                        <div className={style.exam_content}>
                            <div className={style.dynamic_section}>
                                <h3 className={style.question_container}>
                                    <b>Question {questionIndex + 1}</b>
                                    {/* <img
                                className={style.down_img}
                                src="https://www.nta.ac.in/img/QuizIcons/down.png"
                                alt="down-page"
                            /> */}
                                </h3>

                                {/* question and answer  */}
                                <div className={style.user_dynamic_question}>
                                    <DecodedHTMLComponent data={question?.question?.question} />
                                    <div id={`exam_question_${question?.id}`} className={style.exam_answer} key={question?.id}>
                                        <DecodedHTMLComponent data={question?.question?.answer_type} key={clear} />
                                    </div>
                                </div>

                                {/* action all start */}
                                <div className={style.user_response_buttons}>
                                    <button
                                        className={style.user_bt}
                                        onClick={() => SaveNext(false)}
                                        style={{ backgroundColor: "rgb(92, 184, 92)" }}
                                    >
                                        SAVE AND NEXT
                                    </button>
                                    <button
                                        className={style.user_bt}
                                        onClick={() => ClearResponse()}
                                        style={{
                                            backgroundColor: "#fff",
                                            color: "black",
                                            border: "1px solid lightgrey",
                                        }}
                                    >
                                        CLEAR
                                    </button>
                                    <button
                                        onClick={() => SaveNext(true)}
                                        className={style.user_bt}
                                        style={{ backgroundColor: "#f0ad4e" }}
                                    >
                                        SAVE AND MARK FOR REVIEW
                                    </button>
                                    <button
                                        onClick={() => MarkForReview(true)}
                                        className={style.user_bt}
                                        style={{ backgroundColor: "#337ab7" }}
                                    >
                                        MARK FOR REVIEW AND NEXT
                                    </button>
                                </div>

                                <div className={style.footer_buttons}>
                                    <button className={` ${style.back_bt} ${style.footer_bt}`} onClick={() => Back()} >{"<< "}BACK</button>
                                    <button className={` ${style.next_bt} ${style.footer_bt}`} onClick={() => Next()}>NEXT {">>"}</button>
                                    <button
                                        className={` ${style.submit_bt} ${style.footer_bt}`}
                                        onClick={() => Submit()}
                                        style={{ backgroundColor: "rgb(92, 184, 92)", color: "#fff" }}
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                                {/* action all end */}
                            </div>

                            {/* full width  */}
                            <div className={style.full_dynamic_page}>
                                <button
                                    className={style.arrow_bt}
                                    title="Click to full screen"
                                    onClick={handleArrowButtonClick}
                                >
                                    {">"}
                                </button>
                            </div>
                            {/* full width  end*/}


                            {/* right bar  */}
                            <div className={style.user_performance}>
                                <div className={` ${style.user_panel} ${style.custom_panel}`}>
                                    <div className={style.user_panel_table}>
                                        <table key={status} className={` ${style.panel_table_body} ${style.custom_table}`}>
                                            <thead>
                                                <tr>
                                                    <td className={style.full_data}>
                                                        {" "}
                                                        <a className={` ${style.attempted} ${style.data_link}`}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].not_visited}</a>
                                                    </td>
                                                    <td className={style.custom_label}>Not Visited</td>
                                                    <td className={style.full_data}>
                                                        {" "}
                                                        <a className={` ${style.not_attempted} ${style.data_link}`}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].not_answered}</a>{" "}
                                                    </td>
                                                    <td className={style.custom_label}>Not Answered</td>
                                                </tr>
                                                <tr>
                                                    <td className={style.full_data}>
                                                        {" "}
                                                        <a className={` ${style.answered} ${style.data_link}`}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].answered}</a>{" "}
                                                    </td>
                                                    <td className={style.custom_label}>Answered</td>
                                                    <td className={style.full_data}>
                                                        {" "}
                                                        <a className={` ${style.marked_for_review} ${style.data_link}`}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].review}</a>{" "}
                                                    </td>
                                                    <td className={style.custom_label}>Marked for Review</td>
                                                </tr>
                                                <tr>
                                                    <td className={style.full_data}>
                                                        {" "}
                                                        <a className={` ${style.saved_and_marked} ${style.data_link}`}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].answred_review}</a>{" "}
                                                    </td>
                                                    <td colSpan="3" className={` ${style.custom_colspan} ${style.custom_label}`}>
                                                        Answered &amp; Marked for Review (will be considered for
                                                        evaluation)
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>

                                <div className={style.questions_list_links}>
                                    <div
                                        className={style.panel_body}
                                        style={{ height: "250px", overflowY: "scroll", width: "100%" }}
                                    >
                                        {/* {console.log(status?.sectional_status?.length > 0 && status?.sectional_status)} */}
                                        <ul className={`${style.pagination} ${style.test_questions} ${style.custom_pagination}`}>
                                            {status?.sectional_status?.length > 0 && status?.sectional_status[0]?.map((items, i) => (
                                                <li className={`${style.data_seq} ${items == 4 ? `${style.market_review}` : ""} ${items == 3 ? `${style.marked}` : ""}  ${items == 2 ? `${style.not_visited}` : ""} ${items == 1 ? `${style.not_answered}` : ""} ${items == 0 ? `${style.answered}` : ""}`} onClick={() => setquestionIndex(i)}>
                                                    <a >{i + 1}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* right bar  */}

                        </div>
                    </div>

                    <footer className={style.nta_footer}>
                        <center>
                            <p>© All Rights Reserved - National Testing Agency</p>
                        </center>
                    </footer>
                    <Loader modal={modals} player={1} />
                    <Loader modal={modalLoader} player={1} />

                </div>
                :
                <div className={style.summary_page}>

                    <div className={style.user_header}>
                        <nav>
                            <Link to="#" className={style.user_link}>
                                <i className={` ${style.fa} ${style.fa_user}`}></i> Home
                            </Link>
                        </nav>
                    </div>

                    <header className={style.nta_header}>
                        <nav>
                            <div className={style.nta_images}>
                                <Link to="#">
                                    <img
                                        src="https://www.nta.ac.in/img/150g.png"
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_1}`}
                                    />
                                    <img
                                        src="https://www.nta.ac.in/img/NTA_logo.png"
                                        style={{ width: "320px" }}
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_2}`}
                                    />
                                    <img
                                        src="https://www.nta.ac.in/img/moe1.png"
                                        style={{ width: "220px" }}
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_3}`}
                                    />
                                    <img
                                        src="https://www.nta.ac.in/img/akam.png"
                                        style={{ height: "60px" }}
                                        alt="gov-brand"
                                        className={` ${style.logos} ${style.img_4}`}
                                    />
                                </Link>
                            </div>
                        </nav>
                    </header>

                    <header className={style.genral_instructions_header}>
                        <div className={style.user_profile_container}>
                            <div className={style.table_responsive}>
                                <table className={style.user_info_table}>
                                    <tbody>
                                        <tr>
                                            <td className={style.user_icon_cell}>
                                                <i className={` ${style.fa} ${style.fa_user} ${style.user_icon} ${style.student_img}`}></i>
                                            </td>
                                            <td className={style.user_details_cell}>
                                                <table className={style.user_details_table}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={style.detail_label}>Candidate Name</td>
                                                            <td className={style.detail_value}>
                                                                {" "}
                                                                :{" "}
                                                                <span className={style.highlight_text}>{Cookies.get('firstName')} {Cookies.get('lastName')} </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Exam Name</td>
                                                            <td className={style.detail_label}>
                                                                {" "}
                                                                : <span className={style.highlight_text}>{selector.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Subject Name</td>
                                                            <td className={style.detail_value}>
                                                                {" "}
                                                                : <span className={style.highlight_text}>{sectionOrder.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Remaining Time</td>
                                                            <td className={style.detail_value}>
                                                                {" "}
                                                                :{" "}
                                                                <span className={` ${style.timer_title} ${style.time_started}`}>
                                                                    02:58:46
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </header>

                    <div className={style.user_summary}>
                        <div className={style.panel_of_summary}>
                            <h3 className={style.user_title}>EXAM SUMMARY</h3>
                            <table className={style.summary_table}>
                                <thead>
                                    <tr>
                                        <th className={style.q_count}>No of Questions</th>
                                        <th className={style.answered}>Answered</th>
                                        <th className={style.not_answered}>Not Answered</th>
                                        <th>Marked for Review</th>
                                        <th className={style.reviewed_answered}>
                                            Answered &amp; Marked for Review (will be considered for
                                            evaluation)
                                        </th>
                                        <th className={style.not_visited}>Not Visited</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={style.dynamic_data}>
                                        <td className={style.q_total}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].review + status?.group_status[questionStatus].answered + status?.group_status[questionStatus].not_answered + status?.group_status[questionStatus].answred_review + status?.group_status[questionStatus].not_visited}</td>
                                        <td className={style.answered_count}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].answered}</td>
                                        <td className={style.not_answered_count}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].not_answered}</td>
                                        <td className={style.review_count}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].review}</td>
                                        <td className={style.reviewed_answered_count}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].answred_review}</td>
                                        <td className={style.not_visited_count}>{status?.sectional_status?.length > 0 && status?.group_status[questionStatus].not_visited}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={style.center_panel}>
                        <h3 className={style.user_confirmation}>
                            Are you sure you want to submit for final marking?
                            <br />
                            No changes will be allowed after submission.
                        </h3>
                    </div>

                    <div className={style.user_confirm_buttons}>
                        <button className={style.confirm_bt} onClick={() => SubmitTest()}>YES</button>
                        <button className={style.confirm_bt} onClick={() => NotSubmit()}>NO</button>
                    </div>

                    <footer className={style.nta_footer}>
                        <center>
                            <p>© All Rights Reserved - National Testing Agency</p>
                        </center>
                    </footer>

                </div>
            }
        </>
    )
}

export const Instructions = ({ instruction, setQuestionView }) => {

    const [disable, setDisable] = useState(false)

    const QuestionStart = () => {
        setQuestionView(0)
    }

    return (
        <div className={style.instruction_page}>
            <div className={style.user_header}>
                <nav>
                    {/* <Link to="#" className={style.user_link}> */}
                    <i className={` ${style.fa} ${style.fa_user}`}></i> Home
                    {/* </Link> */}
                </nav>
            </div>

            <header className={style.nta_header}>
                <nav>
                    <div className={style.nta_images}>
                        {/* <Link to="#"> */}
                        <img
                            src="https://www.nta.ac.in/img/150g.png"
                            alt="gov-brand"
                            className={` ${style.logos} ${style.img_1}`}
                        />
                        <img
                            src="https://www.nta.ac.in/img/NTA_logo.png"
                            style={{ width: "320px" }}
                            alt="gov-brand"
                            className={` ${style.logos} ${style.img_2}`}
                        />
                        <img
                            src="https://www.nta.ac.in/img/moe1.png"
                            style={{ width: "220px" }}
                            alt="gov-brand"
                            className={` ${style.logos} ${style.img_3}`}
                        />
                        <img
                            src="https://www.nta.ac.in/img/akam.png"
                            style={{ height: "60px" }}
                            alt="gov-brand"
                            className={` ${style.logos} ${style.img_4}`}
                        />
                        {/* </Link> */}
                    </div>
                </nav>
            </header>

            <header className={style.genral_instructions_header}>
                <h1 className={style.genral}>GENERAL INSTRUCTIONS</h1>
            </header>

            <div className={style.contents}>
                <div className={style.instructions_container}>
                    <DecodedHTMLComponent data={instruction} />
                    {/* <h1 className={style.title_h1}>
                        <b>Please read instructions carefully</b>
                    </h1>
                    <h2 className={style.title}>
                        <u>General Instructions:</u>
                    </h2>

                    <ol className={style.instruction_lists}>
                        <li>Total duration of CMAT - CMAT 2020 is 180 min.</li>
                        <li>
                            The clock will be set at the server. The countdown timer in the
                            top right corner of screen will display the remaining time
                            available for you to complete the examination. When the timer
                            reaches zero, the examination will end by itself. You will not be
                            required to end or submit your examination.
                        </li>
                        <li>
                            The Questions Palette displayed on the right side of screen will
                            show the status of each question using one of the following
                            symbols:
                            <ol className={style.nested_list}>
                                <li>
                                    <img
                                        className={style.symbols}
                                        src="https://www.nta.ac.in/img/QuizIcons/Logo1.png"
                                        alt="symbol"
                                    ></img>
                                    You have not visited the question yet.
                                </li>
                                <li>
                                    <img
                                        src="https://www.nta.ac.in/img/QuizIcons/Logo2.png"
                                        alt="symbol"
                                        className={style.symbols}
                                    ></img>
                                    You have not answered the question.
                                </li>
                                <li>
                                    <img
                                        src="https://www.nta.ac.in/img/QuizIcons/Logo3.png"
                                        alt="symbol"
                                        className={style.symbols}
                                    ></img>
                                    You have answered the question.
                                </li>
                                <li>
                                    <img
                                        src="https://www.nta.ac.in/img/QuizIcons/Logo4.png"
                                        alt="symbol"
                                        className={style.symbols}
                                    ></img>
                                    You have NOT answered the question, but have marked the
                                    question for review.
                                </li>
                                <li>
                                    <img
                                        src="https://www.nta.ac.in/img/QuizIcons/Logo5.png"
                                        alt="symbol"
                                        className={style.symbols}
                                    ></img>
                                    The question(s) &quot;Answered and Marked for Review&quot;
                                    will be considered for evalution.
                                </li>
                            </ol>
                        </li>
                        <li>
                            You can click on the &quot;&gt;&quot; arrow which apperes to the
                            left of question palette to collapse the question palette thereby
                            maximizing the question window. To view the question palette
                            again, you can click on &quot;&lt;&quot; which appears on the
                            right side of question window.
                        </li>
                        <li>
                            You can click on your &quot;Profile&quot; image on top right
                            corner of your screen to change the language during the exam for
                            entire question paper. On clicking of Profile image you will get a
                            drop-down to change the question content to the desired language.
                        </li>
                        <li>
                            You can click on{" "}
                            <img
                                src="https://www.nta.ac.in/img/QuizIcons/down.png"
                                alt="down"
                                className={style.symbols}
                                style={{ height: "20px", width: "20px" }}
                            />
                            to navigate to the bottom and{" "}
                            <img
                                src="https://www.nta.ac.in/img/QuizIcons/up.png"
                                alt="up"
                                className={style.symbols}
                            />
                            to navigate to the top of the question are, without scrolling.
                        </li>
                    </ol>

                    <h2 className={style.title}>
                        <u>Navigating to a Question:</u>
                    </h2>

                    <ol className={style.instruction_lists} start={7}>
                        <li>To answer a question, do the following:</li>
                        <ol type="a">
                            <li>
                                Click on the question number in the Question Palette at the
                                right of your screen to go to that numbered question directly.
                                Note that using this option does NOT save your answer to the
                                current question.
                            </li>
                            <li>
                                Click on <b>Save & Next</b> to save your answer for the current
                                question and then go to the next question.
                            </li>
                            <li>
                                Click on <b>Mark for Review & Next</b> to save your answer for
                                the current question, mark it for review, and then go to the
                                next question.
                            </li>
                        </ol>
                    </ol>

                    <h2 className={style.title}>
                        <u>Answering a Question:</u>
                    </h2>

                    <ol className={style.instruction_lists} start={8}>
                        <li>
                            Procedure for answering a multiple choice type question:
                            <ol type="a">
                                <li>
                                    To select you answer, click on the button of one of the
                                    options.
                                </li>
                                <li>
                                    To deselect your chosen answer, click on the button of the
                                    chosen option again or click on the <b>Clear Response </b>
                                    button
                                </li>
                                <li>
                                    To change your chosen answer, click on the button of another
                                    option
                                </li>
                                <li>
                                    To save your answer, you MUST click on the Save & Next button.
                                </li>
                                <li>
                                    To mark the question for review, click on the Mark for Review
                                    & Next button.
                                </li>
                            </ol>
                        </li>
                        <li>
                            To change your answer to a question that has already been
                            answered, first select that question for answering and then follow
                            the procedure for answering that type of question.
                        </li>
                    </ol>

                    <h2 className={style.title}>
                        <u>Navigating through sections:</u>
                    </h2>

                    <ol className={style.instruction_lists} start={10}>
                        <li>
                            Sections in this question paper are displayed on the top bar of
                            the screen. Questions in a section can be viewed by click on the
                            section name. The section you are currently viewing is
                            highlighted.
                        </li>
                        <li>
                            After click the Save & Next button on the last question for a
                            section, you will automatically be taken to the first question of
                            the next section.
                        </li>
                        <li>
                            You can shuffle between sections and questions anything during the
                            examination as per your convenience only during the time
                            stipulated.
                        </li>
                        <li>
                            Candidate can view the corresponding section summery as part of
                            the legend that appears in every section above the question
                            palette.
                        </li>
                    </ol>

                    <span className={style.note}>
                        Please note all questions will appear in your default language. This
                        language can be changed for a particular question later on.
                    </span> */}

                    <label className={style.agreement_box}>
                        <input type="checkbox" className={style.input_box} onClick={(e) => setDisable(e.target.checked)} />
                        &nbsp;&nbsp;I have read and understood the instructions. All
                        computer hardware allotted to me are in proper working condition. I
                        declare that I am not in possession of {"/"} not wearing {"/"} not
                        carrying any prohibited gadget like mobile phone, bluetooth devices
                        etc. {"/"} any prohibited material with me into the Examination
                        Hall.I agree that in case of not adhering to the instructions, I
                        shall be liable to be debarred from this Test and/or to disciplinary
                        action, which may include ban from future Tests {"/"} Examinations
                    </label>

                    <center>
                        <button className={style.proceed} disabled={disable ? false : true} onClick={() => QuestionStart()}>PROCEED</button>
                    </center>
                </div>
            </div>
            <footer className={style.nta_footer}>
                <center>
                    <p>© All Rights Reserved - National Testing Agency</p>
                </center>
            </footer>
        </div>

    )
}

export const ThankyouScreen = () => {
    const [queryParameters] = useSearchParams()
    const testId = queryParameters.get("test_id")
    const selector = useSelector((state) => state.GET_INFO_REDUCER)


    const ScoreCard = () => {
        const BASE_URL = process.env.REACT_APP_API_KEY;
        var play_url = "https://play.imsindia.com/";

        if (BASE_URL.includes("staging")) {
            play_url = "https://stagingplay.imsindia.com/";
        }
        window.location.replace(`${play_url}full-review/?test_id=${testId}`);
    }



    return (
        <div className={style.thanks_page}>
            <div className={style.user_header}>
                <nav>
                    <Link to="#" className={style.user_link}>
                        <i className={` ${style.fa} ${style.fa_user}`}></i> Home
                    </Link>
                </nav>
            </div>

            <header className={style.nta_header}>
                <nav>
                    <div className={style.nta_images}>
                        <Link to="#">
                            <img
                                src="https://www.nta.ac.in/img/150g.png"
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_1}`}
                            />
                            <img
                                src="https://www.nta.ac.in/img/NTA_logo.png"
                                style={{ width: "320px" }}
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_2}`}
                            />
                            <img
                                src="https://www.nta.ac.in/img/moe1.png"
                                style={{ width: "220px" }}
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_3}`}
                            />
                            <img
                                src="https://www.nta.ac.in/img/akam.png"
                                style={{ height: "60px" }}
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_4}`}
                            />
                        </Link>
                    </div>
                </nav>
            </header>

            <header className={style.genral_instructions_header}>
                <div className={style.user_profile_container}>
                    <div className={style.table_responsive}>
                        <table className={style.user_info_table}>
                            <tbody>
                                <tr>
                                    <td className={style.user_icon_cell}>
                                        <i className={` ${style.fa} ${style.fa_user} ${style.user_icon} ${style.student_img}`}></i>
                                    </td>
                                    <td className={style.user_details_cell}>
                                        <table className={style.user_details_table}>
                                            <tbody>
                                                <tr>
                                                    <td className={style.detail_label}>Candidate Name</td>
                                                    <td className={style.detail_value}>
                                                        {" "}
                                                        :{" "}
                                                        <span className={style.highlight_text}>{Cookies.get('firstName')} {Cookies.get('lastName')} </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.detail_label}>Exam Name</td>
                                                    <td className={style.detail_label}>
                                                        {" "}
                                                        : <span className={style.highlight_text}>{selector.name}</span>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                            <td className={style.detail_label}>Subject Name</td>
                                                            <td className={style.detail_value}>
                                                                {" "}
                                                                : <span className={style.highlight_text}>{sectionOrder.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={style.detail_label}>Remaining Time </td>
                                                            <td className={style.detail_value}>
                                                                <CountdownTimer key={time_left?.time_left} initialHours={0} initialMinutes={time_left?.time_left}
                                                                    // openModal={() => openModalConclude({
                                                                    //     title: "Time Expired",
                                                                    //     description: "Time has expired for this section, click OK to continue."
                                                                    // })} 
                                                                    text={""} />
                                                            </td>
                                                        </tr> */}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </header>
            <div className={style.thankyou}>
                <h2 className={style.thanks_line}>Thank you, Submitted Successfully.</h2>
            </div>

            <div className={style.user_result_button}>
                <button className={style.view_result} onClick={() => ScoreCard()}>VIEW RESULT</button>
            </div>

            <footer className={style.nta_footer}>
                <center>
                    <p>© All Rights Reserved - National Testing Agency</p>
                </center>
            </footer>
        </div>
    )
}

export const UserResult = () => {
    return (
        <div className={style.user_result_page}>
            <div className={style.user_header}>
                <nav>
                    <Link to="#" className={style.user_link}>
                        <i className={` ${style.fa} ${style.fa_user}`}></i> Home
                    </Link>
                </nav>
            </div>

            <header className={style.nta_header}>
                <nav>
                    <div className={style.nta_images}>
                        <Link to="#">
                            <img
                                src="https://www.nta.ac.in/img/150g.png"
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_1}`}
                            />
                            <img
                                src="https://www.nta.ac.in/img/NTA_logo.png"
                                style={{ width: "320px" }}
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_2}`}
                            />
                            <img
                                src="https://www.nta.ac.in/img/moe1.png"
                                style={{ width: "220px" }}
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_3}`}
                            />
                            <img
                                src="https://www.nta.ac.in/img/akam.png"
                                style={{ height: "60px" }}
                                alt="gov-brand"
                                className={` ${style.logos} ${style.img_4}`}
                            />
                        </Link>
                    </div>
                </nav>
            </header>

            <header className={style.genral_instructions_header}>
                <div className={style.user_profile_container}>
                    <div className={style.table_responsive}>
                        <table className={style.user_info_table}>
                            <tbody>
                                <tr>
                                    <td className={style.user_icon_cell}>
                                        <i className={` ${style.fa} ${style.fa_user} ${style.user_icon} ${style.student_img}`}></i>
                                    </td>
                                    <td className={style.user_details_cell}>
                                        <table className={style.user_details_table}>
                                            <tbody>
                                                <tr>
                                                    <td className={style.detail_label}>Candidate Name</td>
                                                    <td className={style.detail_value}>
                                                        {" "}
                                                        :{" "}
                                                        <span className={style.highlight_text}>[Your Name]</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.detail_label}>Exam Name</td>
                                                    <td className={style.detail_value}>
                                                        {" "}
                                                        : <span className={style.highlight_text}>CMAT</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.detail_label}>Subject Name</td>
                                                    <td className={style.detail_value}>
                                                        {" "}
                                                        : <span className={style.highlight_text}>CMAT 2020</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.detail_label}>Remaining Time</td>
                                                    <td className={style.detail_value}>
                                                        {" "}
                                                        :{" "}
                                                        <span className={` ${style.timer_title} ${style.time_started}`}>
                                                            02:58:46
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </header>

            <div className={style.result_panel}>
                <div className={style.score_card}>
                    <table className={style.feedback_table}>
                        <thead>
                            <tr className={style.review}>
                                <th colSpan="3">
                                    <h5 className={style.feedback_title}>
                                        Please provide your valuable feedback about Mock Test:
                                    </h5>
                                    <Link to="#" target="_blank" className={` ${style.btn} ${style.btn_feedback}`}>
                                        Student Feedback{" "}
                                    </Link>
                                </th>
                                <th>
                                    <Link to="#" className={` ${style.btn} ${style.btn_back}`}>
                                        Back
                                    </Link>
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="4">Score Card</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Total Question</td>
                                <th className={style.total_question}>100</th>
                                <td>Total Attempted</td>
                                <th className={style.total_attempted}>0</th>
                            </tr>
                            <tr>
                                <td>Correct Answers</td>
                                <th className={style.total_correct}>0</th>
                                <td>Incorrect Answers</td>
                                <th className={style.total_wrong}>0</th>
                            </tr>
                            <tr>
                                <td>Score</td>
                                <td>0</td>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={style.user_score_data}>
                    <div className={style.user_interface}>
                        <div className={style.entries_bar}>
                            <label className={style.show_entries}>
                                Show
                                <select
                                    name="theadres_length"
                                    aria-controls="theadres"
                                    className={style.form_control}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>{" "}
                                entries
                            </label>
                        </div>
                        <div className={style.search_bar}>
                            <label className={style.search_label}>
                                Search:
                                <input
                                    type="search"
                                    className={style.form_control}
                                    placeholder=""
                                    aria-controls="theadres"
                                />
                            </label>
                        </div>
                    </div>

                    <div className={style.user_full_dynamic_score}>
                        <table className={style.result_table}>
                            <thead>
                                <tr>
                                    <th className={style.sort} data-label="Question No.">
                                        Question No.
                                    </th>
                                    <th className={style.sort} data-label="Selected Option">
                                        Selected Option
                                    </th>
                                    <th className={style.sort} data-label="Status">
                                        Status
                                    </th>
                                    <th className={style.sort} data-label="Correct Option">
                                        Correct Option
                                    </th>
                                </tr>
                            </thead>
                            <tbody id={style.tbodyResult}>
                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>

                                <tr className={style.odd}>
                                    <td>Question 1:</td>
                                    <td>---</td>
                                    <td>N/A</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={style.jump_to_pages_content}>
                        <div className={style.area_entries_showing}>
                            Showing 1 to 10 of 100 entries
                        </div>

                        <div className={style.jump_to_pages}>
                            <ul className={style.user_pagination}>
                                <li className={` ${style.pagination_item} ${style.previous} ${style.disabled}`}>
                                    <Link href="#" tabIndex="0">
                                        Previous
                                    </Link>
                                </li>
                                <li className={` ${style.pagination_item} ${style.active}`}>
                                    <Link href="#" tabIndex="0">
                                        1
                                    </Link>
                                </li>
                                <li className={style.pagination_item}>
                                    <Link href="#" tabIndex="0">
                                        2
                                    </Link>
                                </li>
                                <li className={style.pagination_item}>
                                    <Link href="#" tabIndex="0">
                                        3
                                    </Link>
                                </li>
                                <li className={style.pagination_item}>
                                    <Link href="#" tabIndex="0">
                                        4
                                    </Link>
                                </li>
                                <li className={style.pagination_item}>
                                    <Link href="#" tabIndex="0">
                                        5
                                    </Link>
                                </li>
                                <li className={` ${style.pagination_item} ${style.disabled}`}>
                                    <Link href="#" tabIndex="0">
                                        …
                                    </Link>
                                </li>
                                <li className={style.pagination_item}>
                                    <Link href="#" tabIndex="0">
                                        10
                                    </Link>
                                </li>
                                <li className={` ${style.pagination_item} ${style.next}`}>
                                    <Link href="#" tabIndex="0">
                                        Next
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <footer className={style.nta_footer}>
                <center>
                    <p>© All Rights Reserved - National Testing Agency</p>
                </center>
            </footer>
        </div>
    )
}

const Nta = () => {

    const dispatch = useDispatch()
    const [queryParameters] = useSearchParams()
    const [PlayerStatus, setPlayerStatus] = useState({})

    const selector = useSelector((state) => state.GET_INFO_REDUCER)

    useEffect(() => {
        if (Object.keys(selector).length > 0) {
            setPlayerStatus(selector)
        }
    }, [selector])


    useEffect(() => {
        const intervalId = setInterval(() => {
          dispatch(Ping(queryParameters.get("test_id"))); // Dispatch the action
        }, 10000); // 10 seconds in milliseconds
    
        return () => clearInterval(intervalId); // Clean up the interval on component unmount
      }, []);
    


    const [questionView, setQuestionView] = useState(1)
    useEffect(() => {
        if (Object.keys(PlayerStatus).length > 0) {

            if (PlayerStatus?.status == 2 || PlayerStatus?.status == 1) {
                setQuestionView(0)
            }
        }
    }, [PlayerStatus])

    const isMobileView = useMobileCheck()

    if (!isMobileView) {
        return <div>


            {questionView === 0 &&
                <div>
                    <QuestionView setQuestionView={setQuestionView} />
                </div>
            }

            {questionView === 1 &&
                <div>
                    <Instructions instruction={PlayerStatus?.instruction} setQuestionView={setQuestionView} />
                </div>
            }

            {questionView === 2 && <ThankyouScreen />}


        </div>
    }
    return <NotCompatible />

}

export default Nta