import './App.css';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import ScoreCard from './pages/Scorecard/index';


function App() {

  return (
   <>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/score-card" element={<ScoreCard/>} />
        </Routes>
   </>
  );
}

export default App;
