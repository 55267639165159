// import { BASIC_INFO, BASIC_INFORMATION, CLEAR_ALL_DATA, QUESTION_BANK, QUESTION_CREATION, QUESTION_CREATION_CANCEL, QUESTION_CREATION_CHOOSE_BACK, QUESTION_CREATION_ONE, QUESTION_CREATION_SCREEN_CANCEL, QUESTION_CREATION_TWO, SCREEN_TO_ZERO, SELECT_QUESTION_BANK, SELECT_QUESTION_BANK_RESPONSE, SEND_FOR_REVIEW } from "./constant";

import { GET_API_STATUS_NON_TWO_HUNDRES, GET_GROUP_SUCCESSED, GET_INFO_DATA, GET_SELECTED_QUESTION_DETAILS_SUCCEEDED, GET_TIME_LEFT_INSTRUCTION_SUCCESSED, GET_TIME_LEFT_SUCCESSED } from "./constant"


// ____________ INITIAL DATA OF REDUCER ____________
export const infoData = {
}

export const questionData = {
}

export const timeleft = {

}

export const apiStatus ={
    status:false
}

// ____________ REDUCERS ____________



export const GET_INFO_REDUCER = (data = infoData, action) => {
    switch (action.type) {
        case GET_INFO_DATA:
            return { ...action.response.data }
        case GET_GROUP_SUCCESSED:
            // console.log("GET_GROUP_SUCCESSED", data,action.data)
            return {
                ...data,
                group_details: action.data.group_details
            };
        default:
            return data
    }
}

export const GET_TIME_LEFT = (data = infoData, action) => {
    // console.log(action)
    switch (action.type) {
        case GET_TIME_LEFT_SUCCESSED:
            return { ...action.data.data }
        case GET_TIME_LEFT_INSTRUCTION_SUCCESSED:
            return { ...action.data.data }
        default:
            return data
    }
}


export const GET_SCORECARD_QUESTION_DETAIL = (data = timeleft, action) => {
    switch (action.type) {
        case GET_SELECTED_QUESTION_DETAILS_SUCCEEDED:
            return { ...action.data.data }
        default:
            return data
    }
}


export const GET_API_STATUS = (data = apiStatus, action)=>{
    // console.log(data, action)
    switch (action.type) {
        case GET_API_STATUS_NON_TWO_HUNDRES:
            return {"status":action.data}
        default:
            return data
    }
}

// export const timerReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'UPDATE_TIMER':
//         return action.payload;
//       default:
//         return state;
//     }
//   };